<template>
  <div
    v-if="numberOfDuplicates > 0"
    ref="wrapperRef"
    :id="`m_duplicates_${id}`"
    class="m-duplicates"
    @mouseleave="closeDropdown"
  >
    <div
      ref="duplicatesRef"
      :id="`m_duplicates_${id}_wrapper`"
      class="m-clickable pa-1"
      :data-tooltip-content="tooltip.content"
      :data-tooltip-position="tooltip.position"
      @mouseover.stop="getDuplicates"
      @click.stop="openDropdown = true"
    >
      <m-icon
        :id="id"
        icon="duplicates-on"
        size="small"
        status="active"
        @click="openDropdown = true"
      />
      <h5 class="type--xsmall">{{ numberOfDuplicates }}</h5>
    </div>
    <span :id="`m_duplicates_${id}`" class="visually-hidden">
      {{ t("articles.seeDuplicates", { count: numberOfDuplicates }) }}
    </span>
    <m-dropdown
      ref="dropdownRef"
      :id="id"
      v-model:visible="openDropdown"
      :labelledBy="`m_duplicates_${id}`"
      :options="duplicateDocs"
      :position="['left', 'top']"
      v-slot="slotProps"
      loading
      @mouseenter="keepDropdownOpen"
    >
      <h5 :class="`type--xsmall`" @click="goToArticle(slotProps.option)">
        {{ getDuplicatesFeed(slotProps.option).name }}
      </h5>
      <!-- <m-feed-link :feed="getDuplicatesFeed(slotProps.option)" /> -->
    </m-dropdown>
  </div>
</template>

<script setup>
import { ref, computed, unref } from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";
import { useRoute, useRouter } from "vue-router";
import useDropdown from "@hooks/useDropdown";

import MIcon from "@components/MIcon.vue";
import MFeedLink from "@components/MFeedLink.vue";
import MDropdown from "@components/MDropdown.vue";
import { snakeCase } from "lodash-es";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const props = defineProps({
  article: { type: Object, required: true },
  lang: { type: String, required: false },
});

const { t } = useI18n();
const { api } = useApi();
const route = useRoute();
const { push, resolve } = useRouter();
const workspacesStore = useWorkspacesStore();
const { openDropdown, toggleDropdown, keepDropdownOpen, closeDropdown } =
  useDropdown();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);

const id = computed(() => `m_duplicates_${snakeCase(props.article.id)}`);
const viewId = computed(() => route.params.viewId);

const duplicateDocs = ref([]);
const numberOfDuplicates = computed(() => {
  if (props.article?.metadata?.group?.corpora_count)
    return props.article?.metadata?.group?.corpora_count - 1;
  else return props.article?.metadata?.group?.selection_count - 1;
});

let gettingDuplicates = false;

const getDuplicates = async () => {
  if (!gettingDuplicates && duplicateDocs.value?.length == 0) {
    gettingDuplicates = true;
    const dupId = props.article?.metadata?.nearDuplicateId;
    const result = await api.search.getDuplicates(
      viewId.value,
      props.article.id,
      dupId
    );
    if (result.data?.result?.documents) {
      duplicateDocs.value = result.data.result.documents.map((m) => ({
        ...m,
        value: m.id,
        label: getArticleTitle(m),
      }));
    }
    gettingDuplicates = false;
  }
};

const getDuplicatesFeed = (val) => {
  const metadata = val.metadata;
  const feed = {};
  if (metadata?.sourceItemUrlList?.[0]?.includes("twitter.com")) {
    feed.handle = metadata?.socialMediaInfo?.twitter?.Handle;
  }

  feed.name = metadata?.sourceItemFeeds[0]?.name ?? "";
  feed.url = metadata?.sourceItemUrlList?.[0] ?? null;

  return feed;
};

const getArticleTitle = (article) => {
  const translations = article.translations ?? {};
  if (translations[props.lang]?.title) {
    return translations[props.lang]?.title;
  } else {
    return translations[article.documentLang]?.title;
  }
};

const goToArticle = (article) => {
  if (route.name == "createManageFilters") {
    /** If we are previwing articles in the vreate view page we need to open the article in a new tab */
    const routeData = resolve({
      name: "article",
      params: {
        viewId: viewId.value ?? unref(workspaceConfig.value.baseViewId),
        articleId: article.id,
        breadcrumb: getArticleTitle(article), //This suddently doesnt work. Now on article load, populates this title (see MArticle view)
      },
    });
    window.open(routeData.href, "_blank");
  } else {
    push({
      name: "article",
      params: {
        viewId: viewId.value ?? unref(workspaceConfig.value.baseViewId),
        articleId: article.id,
        breadcrumb: getArticleTitle(article), //This suddently doesnt work. Now on article load, populates this title (see MArticle view)
      },
    });
  }
};

const tooltip = computed(() => {
  return {
    content: t("articles.seeDuplicates", { count: numberOfDuplicates.value }),
    position: "bottom-center",
  };
});
</script>

<style scoped lang="scss">
.m-duplicates {
  .m-clickable {
    @include flex(flex-start, center, row);
    gap: math.div($spacing-1, 1);
    @include opacity-inactive;

    * {
      fill: color($pri-action-medium);
      color: color($pri-action-medium);
    }

    &:hover {
      @include opacity-hover;

      * {
        color: color($pri-action);
        fill: color($pri-action);
      }
    }
  }

  :deep(.m-feed-link) {
    width: 100%;
  }
}
</style>
@root/utils/
