<!-- eslint-disable no-unreachable -->
<template>
  <div
    class="m-entity-network m-wrapper"
    :class="`m-wrapper--${viewsStore.nav}`"
  >
    <div class="m-subheader">
      <m-filter
        id="entity_relevance_page_filter"
        ref="mFilterComponent"
        :scoped="false"
        :modelValue="viewFilters"
        :placeholder="$t('components.search.placeholder_searchForAnything')"
        :showContextual="true"
      />
    </div>

    <div class="m-container m-container__wrapper">
      <m-loading v-if="!graphIsCompleted" overlay />
      <template v-else-if="!graphAsData">
        <div v-if="hasFilterGroups" class="m-entity-network--empty">
          <h3 class="type--small type--nowrap type--empty">
            {{ t("views.entity-network.noEntities") }}
          </h3>
          <h6 class="type--small type--nowrap">
            {{ t("views.entity-network.tryAddingEntityTypes") }}
          </h6>
        </div>
        <div v-else class="m-entity-network--empty">
          <h3 class="type--small type--nowrap type--empty">
            {{ t("views.entity-network.noSearchResults") }}
          </h3>
          <h6 class="type--small type--nowrap">
            {{ t("views.entity-network.tryChanging") }}
          </h6>
        </div>
      </template>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
        class="m-ent-net"
        :style="{
          opacity: graphAsData ? 1 : 0,
        }"
        ref="resultSVG"
      ></svg>
    </div>

    <div
      v-if="showExplanationBubble"
      id="explain-connection-bubble"
      class="m-assistant"
    >
      <div class="m-tooltip__assistant">
        <img id="open_chat" :src="monitio_assistant" width="26" />
        <span class="type--small" v-html="explanationBubbleContent"></span>
        <m-icon
          icon="close"
          variant="terciary"
          size="small"
          class="ml-3"
          @click="showExplanationBubble = ''"
        />
      </div>
    </div>

    <div v-if="infoShow" id="info-mindmap">
      <p>
        {{ $t("views.entity-network.press") }}
        <span class="type--700">{{ $t("views.entity-network.shift") }}</span>
        {{ $t("views.entity-network.and") }}
        <span class="type--700">
          {{ $t("views.entity-network.selectAnother") }}
        </span>
        {{ $t("views.entity-network.toSeeConnection") }}
      </p>
    </div>
    <div class="m-ent-net__actions">
      <m-button
        v-if="showConnectionsButton.show"
        id="connections_button"
        variant="primary"
        type="contained"
        size="small"
        :label="$t('views.entity-network.seeConnections')"
        class="m-ent-net__connections"
        @click="showConnections(showConnectionsButton.queryObject)"
      />
      <!-- <m-button
        v-if="showExplainButton.show"
        id="explain_button"
        variant="primary"
        type="contained"
        size="small"
        :label="t('views.entity-network.expainConnection')"
        class="m-ent-net__explain"
        @click="showExplanation"
      /> -->
    </div>
    <label id="m_ent_net_dropdown_label" class="visually-hidden">
      {{ t("navigation.header.workspaces_switch") }}
    </label>
    <m-dropdown
      id="m_ent_net_dropdown"
      labelledBy="m_ent_net_dropdown_label"
      v-model:visible="openDropdown"
      :options="dropdownOpts"
      size="small"
      parent="pointer"
      floating
      @update:selected="dropdownAction"
      @mouseenter="keepDropdownOpen"
      @mouseleave="deselectEntity"
    />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";

import { isTypeMindMapColor, checkWiki } from "../utils/utils";

import { useViewFilters } from "@hooks/useViewFilters";
import { useTooltip } from "@tooltips/useTooltip";

import { delay, isEmpty, debounce, cloneDeep } from "lodash-es";

import MFilter from "@components/filter/MFilter.vue";
import MButton from "@components/MButton.vue";
import MDropdown from "@components/MDropdown.vue";
import MLoading from "@components/MLoading.vue";
import MIcon from "@components/MIcon.vue";
import monitio_assistant from "@assets/illustrations/monitio_assistant.svg";

import * as d3 from "d3";
import { d3v7adaptor } from "@root/utils/d3v7adaptor";
import structuredClone from "@utils/structuredClone";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useViewPropertiesStore } from "@root/store/modules/viewProperties";
import { useAppStore } from "@root/store/app";
import { checkForWikiImage } from "@utils/utils";
import useDropdown from "@hooks/useDropdown";

const { t } = useI18n();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const appStore = useAppStore();
const viewPropertiesStore = useViewPropertiesStore();
const route = useRoute();
const router = useRouter();
const { api } = useApi();
const viewId = computed(() => route.params.viewId);
const userLang = computed(() => userStore.i18nLanguage.split("-")[0]);
const {
  queryObject,
  updateQueryObject,
  graphType,
  preference,
  centerNode,
  dateRestriction,
  refineBy,
  decodeQueryObject,
  navigateWithQueryObject,
} = useViewFilters(router, route);
const { updateTooltipContent } = useTooltip();

const { openDropdown, toggleDropdown, keepDropdownOpen, closeDropdown } =
  useDropdown();

const canAccessAIExplanations = computed(
  () => userStore.details.featureAccess?.["showAIExplanations"] ?? false
);

const selectedEntity = ref(null);

const mFilterComponent = ref(null);
const hasFilterGroups = computed(() => {
  return Boolean(mFilterComponent.value?.cleanedFilterGroups?.length ?? 0);
});

const dropdownAction = (val) => {
  const entity = selectedEntity.value;
  switch (val.value) {
    case "articles":
      gotoArticles(entity);
      break;
    case "center":
      centerNodeFunction(entity);
      break;
  }
};

const deselectEntity = () => {
  closeDropdown(() => {
    selectedEntity.value = null;
  });
};

onMounted(() => {
  iscreated.value = true;
  init(viewId.value, queryObject.value);
  showExplainButton.value.show = false;
  showConnectionsButton.value.show = false;
});

onUnmounted(() => {
  globals?.d3Simulation?.stop();
  d3.select(resultSVG.value).selectAll("*").remove();
});

onBeforeRouteUpdate((to, from) => {
  if (from.name != to.name) return true;
  showExplainButton.value.show = false;
  showExplanationBubble.value = false;
  showConnectionsButton.value.show = infoShow.value = false;
  graphIsCompleted.value = false;
  globals?.d3Simulation?.stop();
  d3.select(resultSVG.value).selectAll("*").remove();

  for (const key in tooltipContent.value) {
    //Reset the toolitps
    if (
      tooltipContent.value[key]?.includes("no connection") ||
      tooltipContent.value[key]?.includes("couldn't find") ||
      tooltipContent.value[key]?.includes("are not mentioned") ||
      tooltipContent.value[key]?.includes("are not related")
    )
      delete tooltipContent.value[key];
  }

  /**
   * Handle the update of the props change
   * Ideally we wouls react to changes to the computed vars (viewId, queryObject) but that causes problems
   * betweens routes. So we need to use this hook, but can't use the computed vars because they only change AFTER
   * route change, and this hook runs BEFORE route change.
   **/
  const toQueryObject = decodeQueryObject(to.query.q);
  init(to.params.viewId, toQueryObject);
});

const dropdownOpts = computed(() => {
  const opts = ["articles", "center"];
  return opts.map((m) => ({ value: m, label: t(`views.entity-network.${m}`) }));
});

const view = computed(() => viewsStore.getViewById(viewId.value));
const viewFilters = computed({
  get() {
    return view.value?.details?.filters;
  },
  set(val) {
    const updatedView = { ...view.value };
    updatedView.details.filters = val;
    // viewsStore.selectView(updatedView);
  },
});

const baseQuery = ref(viewPropertiesStore.baseViewProperties?.query);
const useIPTC = ref(route.query.i === "true");
const iscreated = ref(false);
const hasNodes = ref(false);
const resultSVG = ref(null);

const graphIsCompleted = ref(false);

const globals = {};
const graphAsData = ref(false);

const textPosOffsetY = 5;

const transformPathLabel = (d) => {
  const sourceX = d.source.x + (d.target.x - d.source.x) / 2;
  const sourceY = d.source.y + (d.target.y - d.source.y) / 2;
  return "translate(" + sourceX + "," + sourceY + ")";
};

const getTypeMindMapTypeFootNote = (type) => {
  // NOTE: This is a hack. Must find a better way to decide if a
  //       property type is to be a footnote reference.
  //       There must be metadata somewhere stating if a particular
  //       key is to be a footnote type in MindMap etc, or not.
  const mustContainToBeFootNote = "EPMC$";

  let mindmapLabel = "";
  let isFootnote = false;
  if (viewsStore.currentView?.backendDetails?.mindmap) {
    const mindmapFacet = viewsStore.currentView.backendDetails.mindmap.find(
      (m) => m.Key == type && m.Key.indexOf(mustContainToBeFootNote) >= 0
    );
    if (mindmapFacet) {
      mindmapLabel = "filters." + mindmapFacet.key;
      isFootnote = true;
    }
  }

  const ret =
    (!isTypeMindMapColor(type) &&
      isFootnote && // The mustContainToBeFootNote is a Hack - rethink
      appStore.propertyKeysFilterMap[mindmapLabel] &&
      appStore.propertyKeysFilterIds[
        viewPropertiesStore.propertyKeysFilterMap[mindmapLabel]
      ] &&
      appStore.propertyKeysFilterIds[
        appStore.propertyKeysFilterMap[mindmapLabel]
      ]) ||
    null;

  if (ret != null) {
    return ret.toString();
  }
  return null;
};

const infoShow = ref(false);

const getWikiData = async (entry, elementId) => {
  const params = {
    action: "query",
    prop: "extracts",
    exintro: "",
    explaintext: "",
    format: "json",
    titles: entry.name,
    redirects: 1,
    origin: "*",
  };

  const wiki = await checkWiki(userLang.value, entry.name, "en", params);
  const wikiImg = await checkForWikiImage(
    userLang.value,
    entry.name,
    entry.name,
    userLang.value
  );

  const arabicRegex = /[\u0600-\u06FF]/;
  const tooltip = {
    label: entry.name,
    type: t(`general.facets.${entry.type}`),
    dir: arabicRegex.test(entry.name) ? "rtl" : null,
    wikipedia: "",
    openWikipedia: t("views.trending-entities.openWikipedia"),
    wikipediaLink: wikiImg?.page,
    image: wikiImg?.image || "./monitio_thumbnail.png",
  };

  if (wiki.data.query) {
    const page = wiki.data.query.pages[Object.keys(wiki.data.query.pages)[0]];
    if (!page.extract) {
      tooltip.wikipedia = `<span class="type--empty">${t(
        "views.entity-network.unableToGetExtract"
      )}</span>`;
    } else if (page.extract.length < 250) {
      tooltip.wikipedia = page?.extract;
    } else {
      tooltip.wikipedia = page?.extract.substr(0, 250);
      const idx = tooltip.wikipedia.lastIndexOf(" ");
      tooltip.wikipedia = `${page?.extract.substr(
        0,
        idx + 1
      )} <a data-is-tooltip="true" href="${
        tooltip.wikipediaLink
      }" target="_blank" title="${
        tooltip.openWikipedia
      }" class="type--500 type--small">[...]</a>`;
    }
  } else {
    tooltip.wikipedia = `<span class="type--empty">${t(
      "views.entity-network.unableToGetExtract"
    )}</span>`;
  }

  const element = document.getElementById(elementId);

  element?.setAttribute("data-tooltip-template", "wikipedia");
  element?.setAttribute("data-tooltip-content", JSON.stringify(tooltip));

  if (element) updateTooltipContent(element);
};

const explanationBubbleContent = ref("");
const showExplanationBubble = ref(false);

const showExplainButton = ref({
  show: false,
  queryObject: null,
});

/** @description receives an array of nodeIds, retrieves the explaination and shows it */
const showExplanation = async (val) => {
  //return; // disabled for now in PROD
  // eslint-disable-next-line no-unreachable
  explanationBubbleContent.value = "Loading, please wait...";
  showExplanationBubble.value = true;

  //#region Highlight the path 1st
  for (let i = 0; i < val.length - 1; i++) {
    const id1 = val[i].replaceAll(" ", "");
    const id2 = val[i + 1].replaceAll(" ", "");
    const path =
      document.getElementById(`id-${id1}__${id2}`) ??
      document.getElementById(`id-${id2}__${id1}`);
    console.log(path);
    if (path) {
      path.setAttribute("stroke-width", 2);
      path.setAttribute("stroke", "rgb(25, 88, 204)");
    }
  }
  //#endregion

  try {
    const result = await api.search.getComplexEntitiesConnectionExplanation(
      val.map((x) => {
        const n = globals?.graph?.nodes.find((f) => f.id == x);
        return {
          id: n.id,
          name: n.id,
          label: n.name,
          type: n.type,
          eweight: n.eweight,
        };
      }),
      dateRestriction.value
    );
    // eslint-disable-next-line no-unreachable
    explanationBubbleContent.value = result.data?.result;
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    explanationBubbleContent.value =
      "An error occurred while retrieving the data";
  }
  showExplanationBubble.value = true;
  showExplainButton.value.show = false;
};

const showConnectionsButton = ref({
  show: false,
  queryObject: null,
});

const showConnections = (queryObj) => {
  infoShow.value = false;
  updateQueryObject(queryObj);
};

const setupConnection = (nodes, x) => {
  try {
    if (nodes.length == 1) {
      infoShow.value = true;
      showExplainButton.value.show = false;
      showConnectionsButton.value.show = false;
    } else if (
      nodes?.length == 2 &&
      globals.graph?.links?.some(
        (x) =>
          x.id === `${nodes[0].id}__${nodes[1].id}` ||
          x.id === `${nodes[1].id}__${nodes[0].id}`
      )
    ) {
      // User clicked two entities that are directly connected to each other
      const queryObj = queryObject.value.clone();
      queryObj.contextualFilters = []; // For this we want to always reset the filters
      //if (!query[0].facets.length == 0)
      nodes.forEach((node) => {
        if (node.type == "") return;
        if (
          queryObj.contextualFilters.length > 0 &&
          queryObj.contextualFilters.some((x) => x.value == node.type)
        ) {
          const idx = queryObj.contextualFilters.findIndex(
            (x) => x.value == node.type
          );
          queryObj.contextualFilters[idx].query.push({
            label: node.name,
            value: node.id,
          });
          queryObj.contextualFilters[idx].query.push({
            operator: "and",
          });
        } else {
          queryObj.contextualFilters.push({
            value: node.type,
            query: [
              {
                label: node.name,
                value: node.id,
              },
              {
                operator: "and",
              },
            ],
          });
        }
      });

      if (queryObj.centerNode) delete queryObj.centerNode;
      showConnectionsButton.value.queryObject = queryObj;

      infoShow.value = false;
      if (queryObj?.contextualFilters.length >= 1) {
        showExplainButton.value.show = true;
        showConnectionsButton.value.show = true;
      }
    } else if (nodes?.length == 2) {
      // User clicked two entities that are NOT directly linked to each other
      const nodeIds = findPathBetweenTwoNodes(
        nodes[0],
        nodes[1],
        globals.graph?.links
      );
      if (!nodeIds.length) {
        // Empty array means that the path between the two chosen nodes passses by the middle and that probably doesnt make sense
        // Show a warning saying this to the user
        explanationBubbleContent.value =
          "Cannot find explanation between two entities wich the path pass thourhg the middle of the graph";
        showExplanationBubble.value = true;
        showExplainButton.value.show = false;
      } else {
        showExplanation(nodeIds);
      }
    }
  } catch (error) {
    infoShow.value = false;
    showExplainButton.value.show = false;
    showConnectionsButton.value.show = false;
    console.debug(error);
  }
};

/**
 *  @returns {string[]}
 */
const findPathBetweenTwoNodes = (startNode, endNode, links) => {
  /**
   * @type {string[]}
   */
  const visitedNodeIds = [];

  /**
   * @returns {string[]}
   */
  const recursive_tree_dfs = (nodeId) => {
    // visit our current node
    visitedNodeIds.push(nodeId);

    /**
     * @description Get the node children that we have not visited
     * @type {string[]}
     */
    //console.log(`__${nodeId}$|^${nodeId}__`);
    const nodeChildrenIds = links
      .filter((x) => x.id.match(new RegExp(`__${nodeId}$|^${nodeId}__`)))
      .map((x) => x.id.replace(`${nodeId}__`, "").replace(`__${nodeId}`, ""))
      .filter((x) => x && !visitedNodeIds.includes(x));

    /*     console.log(
      "child of " + nodeId + ":",
      links.filter((x) => x.id.match(new RegExp(`__${nodeId}$|^${nodeId}__`)))
    );
*/
    // If the current node has children go and visit them one at a time
    // note we are passing a delay along for the d3 animations
    // base case is implicity here as it is a node with no children rather than
    // a check if we are null
    if (nodeChildrenIds.length) {
      if (nodeChildrenIds.includes(endNode.id)) {
        //console.log("found it!!!", nodeId, nodeChildrenIds, endNode.id);
        return [nodeId, endNode.id];
      }

      let result = undefined;

      for (const id of nodeChildrenIds) {
        //await new Promise((resolve) => setTimeout(resolve, 4000));
        //console.log("checking child =>>>>>", id);
        const y = recursive_tree_dfs(id);
        //console.log("result of:", id, y);
        if (y?.length > 0) result = [nodeId, ...y];
      }
      return result ?? [];
    }
  };

  const res = recursive_tree_dfs(startNode.id);

  return res;
};

const centerNodeFunction = (entity) => {
  const nid = `${entity.id}$P$${entity.type}`;

  if (
    !(
      entity.name == "" ||
      entity.name == globals.query ||
      (!nid && !centerNode.value && nid == centerNode.value)
    )
  ) {
    const queryObj = queryObject.value.clone();
    queryObj.contextualFilters = []; // For this we want to always reset the filters
    queryObj.centerNode = nid;
    queryObj.dateRestriction = queryObject.value?.dateRestriction;

    queryObj.contextualFilters.push({
      value: entity.type,
      query: [
        {
          label: entity.name,
          value: entity.id,
        },
        {
          operator: "and",
        },
      ],
    });

    queryObject.value = queryObj;
  }
};

const hideConnectionsBtn = () => {
  showExplainButton.value.show = false;
  showConnectionsButton.value.show = false;
};

const updateGraph = (
  centernode = "",
  gotoArticles = () => undefined,
  viewPropertiesStore,
  routerref,
  routeRef
) => {
  // let d3LinkForce = d3
  //   .forceLink()
  //   .links(Object.keys(globals.linkItemMap).map(f => globals.linkItemMap[f]))
  //   .id(function(d) {
  //     return d.id;
  //   });

  globals.d3Simulation = d3v7adaptor(d3).linkDistance(40).avoidOverlaps(true);

  const pageBounds = {
    x: -globals.graphWidth / 2,
    y: -globals.graphHeight / 2,
    width: globals.graphWidth,
    height: globals.graphHeight,
  };
  const nodeRadius = 10;
  const nodes = Object.keys(globals.nodeItemMap).map(
    (f) => globals.nodeItemMap[f]
  );
  const realGraphNodes = nodes.slice(0);
  const topLeft = { x: pageBounds.x + 30, y: pageBounds.y, fixed: true };

  const tlIndex = nodes.push(topLeft) - 1;
  const bottomRight = {
    x: pageBounds.x + pageBounds.width - 30,
    y: pageBounds.y + pageBounds.height,
    fixed: true,
  };
  const brIndex = nodes.push(bottomRight) - 1;
  const constraints = [];
  let multipleCenterNodes = [];
  for (let i = 0; i < realGraphNodes.length; i++) {
    constraints.push({
      axis: "x",
      type: "separation",
      left: tlIndex,
      right: i,
      gap: nodeRadius,
    });
    constraints.push({
      axis: "y",
      type: "separation",
      left: tlIndex,
      right: i,
      gap: nodeRadius,
    });
    constraints.push({
      axis: "x",
      type: "separation",
      left: i,
      right: brIndex,
      gap: nodeRadius,
    });
    constraints.push({
      axis: "y",
      type: "separation",
      left: i,
      right: brIndex,
      gap: nodeRadius,
    });
  }
  globals.d3Simulation
    .nodes(nodes)
    .links(Object.values(globals.linkItemMap))
    .constraints(constraints)
    .jaccardLinkLengths(100, 0.9)
    .start(30);

  globals.circles = d3
    .select("#circle-group")
    .selectAll("rect")
    .data(globals.d3Simulation.nodes(), function (d) {
      return d.id;
    });
  globals.highlight = d3
    .select("#highlight-group")
    .selectAll("rect")
    .data(globals.d3Simulation.nodes(), function (d) {
      return d.id;
    });
  globals.circleText = d3
    .select("#text-group")
    .selectAll("text")
    .data(globals.d3Simulation.nodes(), function (d) {
      return d.id;
    });
  globals.ghostLines = d3
    .select("#path-group-ghost")
    .selectAll("path")
    .data(globals.d3Simulation.links(), function (d) {
      return d.id;
    });
  globals.lines = d3
    .select("#path-group")
    .selectAll("path")
    .data(globals.d3Simulation.links(), function (d) {
      return d.id;
    });
  // globals.lines.select("#path-group").append("title");

  globals.lineText = d3
    .select("#path-label-group")
    .selectAll("text")
    .data(globals.d3Simulation.links(), function (d) {
      return d.id;
    });

  globals.circles.exit().remove();
  globals.circles = globals.circles
    .enter()
    .append("rect")
    .attr("class", "circles")
    .attr("width", function (d) {
      if (d.id === "") {
        return d.width == undefined ? 0 : d.width + 30;
      } else {
        return d.width == undefined ? 0 : d.width - 30;
      }
    })
    .attr("height", function (d) {
      if (d.id === "") {
        return d.height == undefined ? 0 : d.height + 5;
      } else {
        return d.height == undefined ? 0 : d.height;
      }
    })
    .attr("rx", 15)
    .attr("ry", 20)
    .merge(globals.circles);

  globals.highlight.exit().remove();
  globals.highlight = globals.highlight
    .enter()
    .append("rect")
    .attr("class", function (d) {
      if (isTypeMindMapColor(d.type)) {
        return (
          "highlight type--" +
          d.type.replace("_qid", "").replace("qid_", "").toLowerCase()
        );
      }
      return "";
    })
    .attr("width", function (d) {
      if (isTypeMindMapColor(d.type)) {
        return d.width == undefined ? 0 : d.width / 4;
      } else {
        return 0;
      }
    })
    .attr("height", function (d) {
      if (isTypeMindMapColor(d.type)) {
        return d.height == undefined ? 0 : d.height / 4 - 3;
      } else {
        return 0;
      }
    })
    .attr("rx", 2)
    .merge(globals.circles);

  // TODO: Do something like this, although
  //       Need to cycle through all the tspans which
  //       we want to create and simulate rendering the
  //       divs to get the correct text width
  //
  /*
  let test = document.createElement("div");
  document.body.appendChild(test);
  test.class = "texts";
  test.innerText = "S. pneumoniae";
  let testWidth = test.clientWidth + 1;
  */

  globals.circleText.exit().remove();
  globals.circleText = globals.circleText
    .enter()
    .append("text")
    .attr("y", textPosOffsetY)
    .attr("text-anchor", "middle")
    .attr("class", (d) => {
      const id = centernode.split("$P$")[0];
      if (d.name === "") return "h2 type--small";
      if (d.id == id) return "h2 type--small c-pointer";
      return "h6 c-pointer";
    })
    .attr("data-tooltip-clickable", "true")
    .attr("data-tooltip-content", function (d) {
      if (d.id) {
        return JSON.stringify({
          label: d.name,
          type: t(`general.facets.${d.type}`),
          wikipedia: t("views.entity-network.loadingExtract"),
        });
      }
    })
    .attr("data-tooltip-template", function (d) {
      if (d.id) return "wikipediaLoading";
    })
    .attr("data-tooltip-position", "dynamic")
    .html(function (d) {
      if (d.name === "") {
        if (!isEmpty(queryObject?.value?.contextualFilters)) {
          return queryObject?.value?.contextualFilters
            .reduce((prv, crr) => {
              crr?.query
                .filter((x) => !x.operator)
                .forEach((x) => {
                  prv.push(x.label);
                });
              return prv;
            }, [])
            .join(" / ");
        }
        return t("views.entity-network.trending");
      } else {
        //try {
        const footNoteType = getTypeMindMapTypeFootNote(
          d.type,
          viewPropertiesStore,
          viewsStore,
          appStore
        );
        if (footNoteType != null) {
          return (d.name += `<tspan dy="-10" font-size="11">${footNoteType}</tspan>`);
        }

        return d.name;
      }
    })
    .attr("id", function (d) {
      if (d.id === "") return "id-trending";
      if (d.id) {
        let id = d.id;
        id = id.replace(/\s+/g, "");

        return `id-${id}`;
      } else return "id-";
    })
    .attr("style", function (d) {
      const nid = `"${d.id}"$P$${d.type}`;
      if (d.name === "" || d.name === globals.query || nid === centernode) {
        return "cursor: default; max-width: 10px";
      } else {
        return "";
      }
    })
    .call(globals.d3Simulation.drag)
    .on("mouseenter", function (event, d) {
      if (d.id) {
        if (d.id == selectedEntity.value?.id) keepDropdownOpen();

        let nodeId = d.id;
        nodeId = nodeId.replace(/\s+/g, "");
        hoveringOnElementId = `id-${nodeId}`;

        getWikiData(d, hoveringOnElementId);
      }
    })
    .on("mouseleave", function (event, d) {
      if (d.id) {
        if (d.id == selectedEntity.value?.id) deselectEntity();

        let nodeId = d.id;
        if (nodeId == hoveringOnElementId) hoveringOnElementId = null;
        nodeId = nodeId.replace(/\s+/g, "");
      }
    })
    .on("dblclick", (_evt, d) => {
      d.fx = d.x;
      d.fy = d.y;
      const nid = `"${d.id}"$P$${d.type}`;

      //console.log(d.name + " " + globals.query + " " + nid + " " + centernode);
      if (
        !(
          d.name === "" ||
          d.name === globals.query ||
          (nid != undefined && centernode != undefined && nid === centernode)
        )
      ) {
        centerNodeFunction(d);
      }
    })
    .on("click", (event, d) => {
      resetLinesAttributes();
      if (event.shiftKey) {
        const lineId = multipleCenterNodes
          .find((f) => f.line)
          ?.line?.replace(/\s+/g, "");

        if (multipleCenterNodes.length && lineId) {
          //Reset all the lines 1st
          if (lineId) {
            const lineNode = document.getElementById(`id-${lineId}`);

            lineNode.setAttribute("data-selected", "false");
            lineNode.setAttribute("stroke", "rgb(177, 187, 205)");
            lineNode.setAttribute("stroke-width", "1");
          }

          multipleCenterNodes.forEach((fe) => {
            const feId = fe.id.replace(/\s+/g, "");

            document.getElementById(`id-${feId}`).style.fill =
              "rgb(77, 85, 102)";
            document.getElementById(`id-${feId}`).style.fontWeight = "400";
          });

          multipleCenterNodes = [];
        }

        const nodeId = d.id.replace(/\s+/g, "");
        const idx = multipleCenterNodes.findIndex((f) => f.id == d.id);

        if (idx != -1) {
          multipleCenterNodes.splice(idx, 1);

          document.getElementById(`id-${nodeId}`).style.fill =
            "rgb(77, 85, 102)";
          document.getElementById(`id-${nodeId}`).style.fontWeight = "400";
        } else {
          multipleCenterNodes.push(d);

          document.getElementById(`id-${nodeId}`).style.fill =
            "rgb(0, 70, 199)";
          document.getElementById(`id-${nodeId}`).style.fontWeight = "500";
        }

        if (multipleCenterNodes.length > 0) {
          setupConnection(multipleCenterNodes, d);
        }
      } else {
        for (let i = 0; i < multipleCenterNodes.length; i++) {
          let nodeId = multipleCenterNodes[i].id;
          nodeId = nodeId.replace(/\s+/g, "");

          document.getElementById(`id-${nodeId}`).style.fill =
            "rgb(77, 85, 102)";
          document.getElementById(`id-${nodeId}`).style.fontWeight = "400";
        }

        multipleCenterNodes = [];
        hideConnectionsBtn();

        if (d.id !== "") {
          multipleCenterNodes.push(d);
        }

        const nodeId = d.id.replace(/\s+/g, "");

        document.getElementById(`id-${nodeId}`).style.fill = "rgb(0, 70, 199)";
        document.getElementById(`id-${nodeId}`).style.fontWeight = "700";

        selectedEntity.value = d;
        toggleDropdown();

        if (!infoShow.value) infoShow.value = true;
      }
    })
    .merge(globals.circleText);

  globals.ghostLines.exit().remove();
  globals.ghostLines = globals.ghostLines
    .enter()
    .append("line")
    .attr("stroke-width", "1")
    //.attr("stroke-width", "2")
    .attr("stroke", "rgb(177, 187, 205)")
    //.attr("stroke", "rgb(107, 117, 125)")
    .attr("stroke-linecap", "round")
    .attr("id", function (d) {
      if (d.id !== undefined) {
        let id = d.id;
        id = id.replace(/\s+/g, "");

        return `id-${id}`;
      } else {
        return "";
      }
    })
    .attr("class", "ghost-links")
    .merge(globals.ghostLines);

  globals.lines.exit().remove();
  globals.lines = globals.lines
    .enter()
    .append("line")
    .attr("stroke-width", "20")
    .attr("stroke", "#ffffff00")
    .attr("title", function (d) {
      return d.id;
    })
    .attr("id", function (d) {
      return d.id;
    })

    .attr("class", "links")
    .on("mouseenter", function (_evt, d) {
      let nodeId = d.id;
      hoveringOnElementId = nodeId;
      nodeId = nodeId.replace(/\s+/g, "");
      const node = document.getElementById(`id-${nodeId}`);

      node.setAttribute("stroke", "rgb(25, 88, 204)");
      if (canAccessAIExplanations.value) getAssistantExplanation(d, d.id);
    })
    .on("mouseleave", function (_evt, d) {
      let nodeId = d.id;
      if (nodeId == hoveringOnElementId) hoveringOnElementId = null;
      nodeId = nodeId.replace(/\s+/g, "");
      const node = document.getElementById(`id-${nodeId}`);
      if (!node.attributes["data-selected"]) {
        node.setAttribute("stroke", "rgb(177, 187, 205)");
      }
    })
    .on("click", (event, d) => {
      if (event.shiftKey) {
        const lineId = multipleCenterNodes
          .find((f) => f.line)
          ?.line?.replace(/\s+/g, "");

        if (lineId) {
          const lineNode = document.getElementById(`id-${lineId}`);

          lineNode.setAttribute("data-selected", "false");
          lineNode.setAttribute("stroke", "rgb(177, 187, 205)");
          lineNode.setAttribute("stroke-width", "1");

          multipleCenterNodes.forEach((fe) => {
            const feId = fe.id.replace(/\s+/g, "");

            document.getElementById(`id-${feId}`).style.fill =
              "rgb(77, 85, 102)";
            document.getElementById(`id-${feId}`).style.fontWeight = "400";
          });

          multipleCenterNodes = [];
          showExplainButton.value.show = false;
          showConnectionsButton.value.show = false;
        }
      } else {
        resetLinesAttributes();

        let nodeId = d.id;
        if (nodeId == hoveringOnElementId) hoveringOnElementId = null;
        nodeId = nodeId.replace(/\s+/g, "");
        const node = document.getElementById(`id-${nodeId}`);
        if (multipleCenterNodes.length) {
          const lineId = multipleCenterNodes
            .find((f) => f.line)
            ?.line?.replace(/\s+/g, "");

          if (lineId) {
            const lineNode = document.getElementById(`id-${lineId}`);

            lineNode.setAttribute("data-selected", "false");
            lineNode.setAttribute("stroke", "rgb(177, 187, 205)");
            lineNode.setAttribute("stroke-width", "1");
          }

          multipleCenterNodes.forEach((fe) => {
            const feId = fe.id.replace(/\s+/g, "") || "trending";

            document.getElementById(`id-${feId}`).style.fill =
              "rgb(77, 85, 102)";
            document.getElementById(`id-${feId}`).style.fontWeight = "400";
          });
        }

        node.setAttribute("data-selected", "true");
        node.setAttribute("stroke", "rgb(0, 70, 199)");
        node.setAttribute("stroke-width", "2");

        multipleCenterNodes = [{ ...d.source, line: d.id }, d.target];

        multipleCenterNodes.forEach((fe) => {
          const feId = fe.id.replace(/\s+/g, "");

          document.getElementById(`id-${feId}`).style.fill = "rgb(0, 70, 199)";
          document.getElementById(`id-${feId}`).style.fontWeight = "500";
        });

        setupConnection(multipleCenterNodes, d);
      }
    })
    .on("dblclick", (_evt, d) => {
      d.fx = d.x;
      d.fy = d.y;
      gotoArticles(d);
    })
    .merge(globals.lines);

  if (canAccessAIExplanations.value) {
    globals.lines = globals.lines
      .attr("data-tooltip-clickable", "true")
      .attr("data-tooltip-content", function (d) {
        return "{}";
      })
      .attr("data-tooltip-template", "assistantLoading")
      .attr("data-tooltip-position", "dynamic");
  }

  globals.lineText.exit().remove();
  globals.lineText = globals.lineText
    .enter()
    .append("text")
    .attr("class", "link-texts")
    .text(function (d) {
      return d.name;
    }) //.text(function(d) {return d.type;})
    .merge(globals.lineText);

  //let keytester = Math.random(); // this debug proves that only 1 tick is being called

  function tick() {
    //console.log(keytester);// this debug proves that only 1 tick is being called
    if (globals.lines == undefined) return;
    globals.ghostLines
      .attr("x1", function (d) {
        return d.source.x;
      })
      .attr("y1", function (d) {
        return d.source.y;
      })
      .attr("x2", function (d) {
        return d.target.x;
      })
      .attr("y2", function (d) {
        return d.target.y;
      });
    globals.lines
      .attr("x1", function (d) {
        return d.source.x;
      })
      .attr("y1", function (d) {
        return d.source.y;
      })
      .attr("x2", function (d) {
        return d.target.x;
      })
      .attr("y2", function (d) {
        return d.target.y;
      });
    globals.lineText.attr("transform", transformPathLabel);
    globals.circles
      .attr("x", function (d) {
        if (d.x === undefined || d.width === undefined) return 0;
        else if (d.id === "") return d.x - d.width / 2 - 15;
        else return d.x - d.width / 2 + 15;
      })
      .attr("y", function (d) {
        if (d.y === undefined || d.height === undefined) return 0;
        else if (d.id === "") return d.y - d.height / 2 - 3;
        else return d.y - d.height / 2;
      })
      .attr("style", function (d) {
        const nid = `"${d.id}"$P$${d.type}`;

        if (d.name === "" || d.name === globals.query || nid === centernode) {
          return "cursor: default;";
        }
      });
    globals.highlight
      .attr("x", function (d) {
        if (d.x === undefined || d.width === undefined) return 0;
        else return d.x - d.width / 8;
      })
      .attr("y", function (d) {
        if (d.y === undefined || d.height === undefined) return 0;
        else return d.y - d.height / 2;
      });
    // .attr("transform", transform);
    globals.circleText
      .attr("x", function (d) {
        return d.x;
      })
      .attr("y", function (d) {
        // eslint-disable-next-line no-undef
        const h = this.getBBox().height;
        return d.y + h / 4;
      });
  }
  globals.d3Simulation.on("tick", tick);
};

/** @type {string!null} */
let hoveringOnElementId = null;
/** @type {import("vue").Ref<{[key: string]: string}>} */
const tooltipContent = ref({});
/**
 *
 * @param {{target: object, source: object}} entry
 * @param {string} elementId
 */
const getAssistantExplanation = async (entry, elementId) => {
  if (!canAccessAIExplanations.value) return;
  /** @type {import("@root/types.api.local").MonitioAPI.ExplainEntiiesConnectionDTO | import("@root/types.api.local").MonitioAPI.ExplainTrendingDTO} */
  const params = {
    viewId: viewId.value,
    dateRestriction: dateRestriction.value,
    filters: queryObject.value.filters,
  };

  if (!entry.source) {
    params.entityKey = entry.id;
    params.entityLabel = entry.name;
    params.entitySearchPropertyType = entry.type;
  } else if (entry.source.id == "") {
    params.entityKey = entry.target.id;
    params.entityLabel = entry.target.name;
    params.entitySearchPropertyType = entry.target.type;
  } else {
    params.entities = [
      {
        label: entry.source.name,
        key: entry.source.id,
        type: entry.source.type,
      },
      {
        label: entry.target.name,
        key: entry.target.id,
        type: entry.target.type,
      },
    ];
  }

  delay(async () => {
    if (hoveringOnElementId != elementId) return;
    if (tooltipContent.value[elementId]) {
      if (tooltipContent.value[elementId] == "loading") return;
      delay(() => {
        const element = document.getElementById(elementId);
        element?.setAttribute("data-tooltip-template", "assistant");
        element?.setAttribute(
          "data-tooltip-content",
          JSON.stringify({ assistant: tooltipContent.value[elementId] })
        );
        if (element) updateTooltipContent(element);
      }, 200);
      return;
    }
    try {
      tooltipContent.value[elementId] = "loading";

      const { data } = params.entities
        ? await api.search.getEntitiesConnectionExplanation(params)
        : await api.search.getTrendingExplanation(params);

      if (!data) throw new Error("No value was returned");
      const { response, sources } = data;
      tooltipContent.value[elementId] = response;

      const element = document.getElementById(elementId);

      element?.setAttribute("data-tooltip-template", "assistant");
      element?.setAttribute(
        "data-tooltip-content",
        JSON.stringify({ assistant: tooltipContent.value[elementId] })
      );
      if (element) updateTooltipContent(element);
    } catch (error) {
      tooltipContent.value[elementId] = null;
    }
  }, 500);
};

const resetLinesAttributes = () => {
  //Reset lines to normal color
  const lines = document.querySelectorAll("#path-group-ghost .ghost-links");
  lines.forEach((line) => {
    line.setAttribute("data-selected", "false");
    line.setAttribute("stroke", "rgb(177, 187, 205)");
    line.setAttribute("stroke-width", "1");
  });
};

const init = debounce(async (id, query = null) => {
  globals.svg = d3.select(resultSVG.value);
  globals.graphWidth = resultSVG.value.clientWidth;
  globals.graphHeight = resultSVG.value.clientHeight;

  globals.centerX = globals.graphWidth / 2;
  globals.centerY = globals.graphHeight / 2;

  const gradient = globals.svg
    .append("defs")
    .append("radialGradient")
    .attr("id", "radial_gradient");
  gradient.append("stop").attr("offset", "0%").attr("stop-color", "white");
  gradient
    .append("stop")
    .attr("offset", "100%")
    .attr("stop-color", "white")
    .attr("stop-opacity", "0");

  const zoomGLayer = globals.svg.append("g");
  zoomGLayer
    .append("g")
    .attr("id", "path-group-ghost")
    .attr(
      "transform",
      "translate(" + globals.centerX + "," + globals.centerY + ")"
    );
  zoomGLayer
    .append("g")
    .attr("id", "path-group")
    .attr(
      "transform",
      "translate(" + globals.centerX + "," + globals.centerY + ")"
    );
  //.append("title")
  //.text(t("views.entity-network.dblClickLine"));
  zoomGLayer
    .append("g")
    .attr("id", "control-path-group")
    .attr(
      "transform",
      "translate(" + globals.centerX + "," + globals.centerY + ")"
    );
  zoomGLayer
    .append("g")
    .attr("id", "circle-group")
    .attr(
      "transform",
      "translate(" + globals.centerX + "," + globals.centerY + ")"
    );
  // .append("title");
  // .text("Double click to make this the central node");
  zoomGLayer
    .append("g")
    .attr("id", "highlight-group")
    .attr(
      "transform",
      "translate(" + globals.centerX + "," + (globals.centerY + 24) + ")"
    );
  zoomGLayer
    .append("g")
    .attr("id", "text-group")
    .attr(
      "transform",
      "translate(" + globals.centerX + "," + globals.centerY + ")"
    );
  // .append("title");
  // .text(t("views.entity-network.dblClickNode"));

  zoomGLayer
    .append("g")
    .attr("id", "path-label-group")
    .attr(
      "transform",
      "translate(" + globals.centerX + "," + globals.centerY + ")"
    );
  zoomGLayer
    .append("g")
    .attr("id", "control-icon-group")
    .attr(
      "transform",
      "translate(" + globals.centerX + "," + globals.centerY + ")"
    );

  const zoom_handler = d3
    .zoom()
    .filter(function (event) {
      //Only enable wheel zoom and mousedown to pan
      return event.type == "wheel" || event.type == "mousedown";
    })
    .on("zoom", zoom_actions);

  function zoom_actions(event) {
    globals.svg.select("g").attr("transform", event.transform);
  }

  zoom_handler(globals.svg);

  globals.query = baseQuery.value;

  /*  if (params.restrictions) {
      let trimChars = function (str, c) {
        var re = new RegExp("^[" + c + "]+|[" + c + "]+$", "g");
        return str.replace(re, "");
      };

      params.query = "";
      let p0 = params.restrictions.split("$P$")[0];
      let p1 = params.restrictions.split("$P$")[1];
      params.forceCenterNode = trimChars(p0, '"') + "$P$" + p1;
    } */

  const filters = structuredClone(query?.filters ?? []);
  if (!isEmpty(query?.contextualFilters))
    filters.push({ facets: query.contextualFilters });

  try {
    const data = await api.search.entitywalk(
      viewId.value,
      query?.dateRestriction || dateRestriction.value,
      filters,
      query?.refineBy ?? refineBy.value,
      query?.preference ?? preference.value,
      query?.graphType ?? graphType.value,
      useIPTC.value,
      query?.centerNode
    );
    if (data?.data?.nodes?.length > 0) hasNodes.value = true;

    globals.nodeItemMap = {};
    globals.linkItemMap = {};
    globals.graph = {
      nodes: data.data.nodes.map((f, i) => {
        const node = {
          n: parseInt(i),
          id: f.name,
          name:
            data.data.properties.find((x) => x.key == f.name)?.label ??
            f.label ??
            f.name,
          eweight: f.eweight,
          type: f.type,
          width: f.name.length * 2 * (2 + 2 * f.eweight) + 55,
          height: f.eweight + 3 * 6 + 10,
          group: i,
        };
        globals.nodeItemMap[node.id] = node;
        return node;
      }),
      links: data.data.links.map((f) => {
        const link = {
          id: `${f.source}__${f.target}`,
          source: globals.nodeItemMap[f.source],
          target: globals.nodeItemMap[f.target],
          value: f.eweight,
        };

        //console.info(`${f.source}__${f.target}`, structuredClone(link));

        globals.linkItemMap[link.id] = link;
        return link;
      }),
    };

    graphIsCompleted.value = true;
    graphAsData.value = globals?.graph?.nodes?.length > 0;

    updateGraph(
      query?.centerNode,
      gotoArticles,
      viewPropertiesStore,
      router,
      route
    );
  } catch (error) {
    console.debug(error);
    globals.nodeItemMap = {};
    globals.linkItemMap = {};
    globals.graph = { nodes: [], links: [] };
    graphIsCompleted.value = true;
    graphAsData.value = false;
    updateGraph(
      query?.centerNode,
      gotoArticles,
      viewPropertiesStore,
      router,
      route
    );
  }
}, 500);

const gotoArticles = (val) => {
  /** @type {Monitio.URLQueryObject]} */
  const queryObj = queryObject.value.clone(); //Avoid structuredClone() here! It removes needed functions!
  const queryFilters = queryObj.filters ? queryObj.filters : [{ facets: [] }];
  //if (!query[0].facets.length == 0)
  const facets = queryFilters[0].facets;
  if (val.source && val.target) {
    if (val.source.type == val.target.type) {
      facets.push({
        value: val.source.type,
        query: [
          {
            label: val.source.name,
            value: val.source.id,
          },
          {
            operator: "and",
          },
          {
            label: val.target.name,
            value: val.target.id,
          },
        ],
      });
    } else {
      if (val.source.type != "") {
        facets.push({
          value: val.source.type,
          query: [
            {
              label: val.source.name,
              value: val.source.id,
            },
          ],
        });
      }

      if (val.target.type != "") {
        facets.push({
          value: val.target.type,
          query: [
            {
              label: val.target.name,
              value: val.target.id,
            },
          ],
        });
      }
    }
    queryObj.filters = queryFilters;
  } else if (val.type) {
    facets.push({
      value: val.type,
      query: [
        {
          label: val.name,
          value: val.id,
        },
      ],
    });

    queryObj.filters = queryFilters;
  } else if (!isEmpty(queryObj.contextualFilters)) {
    // In this case its either an empty node, or a double node that will be in the contextualFilters, so...copy th
    queryObj.filters = [{ facets: [...queryObj.contextualFilters] }];
  }

  queryObj.dateRestriction = dateRestriction.value;
  delete queryObj.contextualFilters;
  delete queryObj.centerNode;

  console.log(cloneDeep(queryObj));
  navigateWithQueryObject(queryObj, "articles", { viewId: viewId.value });
};
</script>

<style lang="scss">
@import "@stylesheets/scss/components/entitynetwork";
</style>

<style lang="scss" scoped>
.m-wrapper {
  position: relative !important;
}

.m-entity-network {
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;

  &--empty {
    @include flex(center, center, column);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }

  .m-container {
    flex-grow: 1;
    position: relative;

    > svg {
      @include round-corners($spacing-2);
      @include elevate-inset;
    }
  }

  :deep(.h6:hover) {
    fill: color($pri-action-base);
    cursor: pointer;
  }

  .m-loading {
    height: calc(100% - $spacing-5);
    position: absolute;
    background-color: color($white);
    z-index: $z-card;
  }

  #explain-connection-bubble,
  #tooltip-mindmap,
  #info-mindmap {
    padding: $spacing-1 $spacing-2;
    border: 1px solid color($sec-200);
    @include round-corners($spacing-1);
    @include flex(space-between, center, row);
    position: absolute;

    color: color($text-light);
    background-color: color($white);
    @include elevate-button;
    overflow: hidden;
    overflow: hidden;
    z-index: $z-modal + 1;
  }

  #explain-connection-bubble {
    max-width: $column-4;
    right: $spacing-6;
    bottom: $spacing-14 + $spacing-4;

    .m-icon--close {
      transform: translateY(2px);
    }
  }

  #tooltip-mindmap,
  #info-mindmap {
    right: $spacing-6;
    bottom: $spacing-7;
  }
}

.m-popover {
  display: none;
  position: absolute;
  bottom: $spacing-6;
  right: $spacing-5;
}
</style>
