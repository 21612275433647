<template>
  <div
    class="m-saved m-wrapper"
    :class="`m-saved--${layout} m-wrapper--${viewsStore.nav}`"
  >
    <div class="m-subheader">
      <!-- <m-filter
        id="saved_page_filter"
        :scoped="false"
        :modelValue="viewFilters"
        :placeholder="$t('components.search.placeholder_searchForAnything')"
      /> -->
    </div>
    <!-- loading headers -->
    <div v-if="!requestCompleted" class="m-container__header">
      <!-- <m-type-skeleton type="h6" width="100" size="default" :lines="1" /> -->
    </div>
    <div
      v-else
      class="m-container__header"
      :class="{
        'm-container__header--load': !isArticlesAllMarked || loadingArticles,
      }"
    >
      <div v-if="totalNumOfArticles" class="m-container__header">
        <h6 class="type--small">
          {{
            $t("views.savedArticles.header_about", totalNumOfArticles, {
              count: totalNumOfArticles,
            })
          }}
        </h6>
      </div>
    </div>
    <div
      ref="containerRef"
      id="scrollable"
      class="m-container"
      :class="{
        'm-container__load': !isArticlesAllMarked || loadingArticles,
        'm-container--scroll': isScrolled,
      }"
    >
      <div class="m-container__wrapper">
        <div
          v-if="!requestCompleted"
          ref="wrapper"
          class="m-articles__wrapper m-saved__wrapper"
          :class="[
            `m-articles__wrapper--${layout}`,
            { 'm-saved__wrapper--empty': articles?.length == 0 },
          ]"
        >
          <m-article-skeleton v-for="i in articlesSkeleton" :key="i" />
        </div>

        <div
          v-else
          ref="wrapper"
          class="m-articles__wrapper m-saved__wrapper"
          :class="[
            `m-articles__wrapper--${layout}`,
            { 'm-saved__wrapper--empty': articles?.length == 0 },
          ]"
        >
          <m-article
            v-for="(article, i) in articles"
            :key="i"
            :article="article"
            @update:article="
              (key, val) => {
                article[key] = val;
              }
            "
            @changed-bookmarked-status="
              (status) => (status ? totalNumOfArticles++ : totalNumOfArticles--)
            "
            @change-read-status="(status) => changeReadStatus(article, status)"
          />

          <m-loading
            v-if="
              apiSavedArticlesData.length < totalNumOfArticles ||
              !maxArticlesReached
            "
            ref="loadingElement"
            size="small"
            :class="`m-article--${viewsStore.layout}`"
            overlay
          />

          <div
            v-if="articles?.length == 0 && requestCompleted"
            class="m-saved__empty"
          >
            <h3 class="type--small type--nowrap type--empty">
              {{ t("views.savedArticles.noSavedArticles") }}
            </h3>
            <h6 class="type--small type--nowrap type--empty">
              {{ t("views.savedArticles.click") }}
              <m-icon
                id="saved"
                icon="saved"
                variant="secondary"
                status="active"
                class="mx-1"
              />
              {{ t("views.savedArticles.toSave") }}
            </h6>
          </div>
          <m-loading
            v-if="!isArticlesAllMarked || loadingArticles"
            overlay
            blured
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { useViewFilters } from "@hooks/useViewFilters";
import useWindowSize from "@hooks/useWindowSize";
import MArticle from "@components/MArticle.vue";
import MIcon from "@components/MIcon.vue";
import useArticle from "@hooks/useArticle";
import { isEqual, isEmpty } from "lodash-es";

import MLoading from "@components/MLoading.vue";
import MArticleSkeleton from "@components/skeletons/MArticleSkeleton.vue";
import { useViewsStore } from "@root/store/modules/views";
import { eventBus } from "@root/utils/eventBus";
import { onUnmounted } from "vue";
import guid from "@root/utils/guid";
import { useAlertsStore } from "@root/store/modules/alerts";

const { t } = useI18n();
const route = useRoute();
const { api } = useApi();
const viewsStore = useViewsStore();
const alertsStore = useAlertsStore();
const viewId = computed(() => route.params.viewId);
const { changeReadStatus } = useArticle(viewId);
const { windowWidth } = useWindowSize();
const router = useRouter();

const { queryObject, decodeQueryObject, dateRestriction } = useViewFilters(
  router,
  route
);

const containerRef = ref(null);
const loadingArticles = ref(false);

const layout = computed(() => viewsStore.layout);
const view = computed(() => viewsStore.getViewById(viewId.value));

const viewFilters = computed({
  get() {
    return view.value?.details?.filters;
  },
  set(val) {
    const updatedView = { ...view.value };
    updatedView.details.filters = val;
  },
});

const apiSavedArticlesData = ref([]);

const articles = computed(() =>
  apiSavedArticlesData.value.filter((x) => x.isSaved == true)
);

const isArticlesAllMarked = ref(true);
const markAllRead = () => {
  isArticlesAllMarked.value = false;
  articles.value?.filter((f) => {
    if (!f.isRead) f.isRead = true;
  });
  isArticlesAllMarked.value = true;
};
const requestCompleted = ref(false);

// number of skeletons to be placed depending on the screen size
const articlesSkeleton = computed(() => {
  let max;
  if (windowWidth.value <= 1536) {
    max = 6;
  } else if (windowWidth.value >= 1536) {
    max = 12;
  }
  return max;
});

const getSavedArticles = async (vId, date, first = 0, last = 19) => {
  loadingArticles.value = true;
  const response = await api.search.savedArticles(vId, date, null, first, last); //start by seding view id to the backend today

  loadingArticles.value = false;
  requestCompleted.value = true;

  if (response.status == 200) {
    if (response.data.result.documents.length < last - first + 1)
      maxArticlesReached.value = true;

    return {
      articlesData: response.data.result.documents.map((article) => ({
        ...article,
        isSaved: true,
      })),
      articlesCount: response.data.result.numDocuments,
    };
  } else {
    maxArticlesReached.value = true;
    return [];
  }
};

let isGettingMoreArticles = false;
const totalNumOfArticles = ref(0);
const maxArticlesReached = ref(false);
const numberOfArticlesToRequest = ref(20);
const getMoreArticles = async () => {
  isGettingMoreArticles = true;

  const { articlesData, articlesCount } = await getSavedArticles(
    viewId.value,
    dateRestriction.value,
    apiSavedArticlesData.value.length,
    apiSavedArticlesData.value.length - 1 + numberOfArticlesToRequest.value
  );

  console.log(articlesCount);

  totalNumOfArticles.value = articlesCount;
  apiSavedArticlesData.value = [...apiSavedArticlesData.value, ...articlesData];
  isGettingMoreArticles = false;
};

//#region Intersection observer code to load more articles when needed
const loadingElement = ref(null);
let observerWasInitiated = false;
const handleIntersection = (entries, observer) => {
  const info = entries[0];

  //info.isIntersecting;

  console.log(
    info.isIntersecting,
    !isGettingMoreArticles,
    articles.value.length > 0
  );

  if (
    info.isIntersecting &&
    !isGettingMoreArticles &&
    articles.value.length > 0
  ) {
    getMoreArticles();
  }
};
const observer = new IntersectionObserver(handleIntersection, {
  rootMargin: "20% 0%",
  threshold: [0, 0.75],
});
watch(
  () => loadingElement.value,
  (val, old) => {
    /** Initialize an intersectionObserver for each row to fetch the data on demand */
    // eslint-disable-next-line no-unreachable
    if (!val?.$el) {
      if (observerWasInitiated) {
        observer.disconnect();
        observerWasInitiated = false;
      }
    } else {
      if (!observerWasInitiated) {
        observer.observe(val.$el);
        observerWasInitiated = true;
      }
    }
  }
);
//#endregion

const isScrolled = ref(false);
const runScroll = () => {
  isScrolled.value =
    containerRef.value?.getBoundingClientRect()?.top !=
    containerRef.value?.childNodes[0]?.getBoundingClientRect()?.y;
};
onMounted(async () => {
  containerRef.value.addEventListener("scroll", runScroll);
  const { articlesData, articlesCount } = await getSavedArticles(
    viewId.value,
    dateRestriction.value,
    apiSavedArticlesData.value.length,
    apiSavedArticlesData.value.length - 1 + numberOfArticlesToRequest.value
  );

  console.log(articlesCount);

  totalNumOfArticles.value = articlesCount;
  apiSavedArticlesData.value = [...apiSavedArticlesData.value, ...articlesData];
});

onBeforeRouteUpdate(async (to, from) => {
  if (from.name != to.name) return true;
  if (isEqual(to.params, from.params) && isEqual(to.query, from.query)) return;
  /**
   * Handle the update of the props change
   * Ideally we would react to changes to the computed vars (viewId, queryObject) but that causes problems
   * betweens routes. So we need to use this hook, but can't use the computed vars because they only change AFTER
   * route change, and this hook runs BEFORE route change.
   **/
  loadingArticles.value = true;
  totalNumOfArticles.value = 0;

  try {
    const { articlesData, articlesCount } = await getSavedArticles(
      to.params.viewId,
      decodeQueryObject(to.query.q)?.dateRestriction ?? dateRestriction.value,
      undefined,
      undefined
    );

    totalNumOfArticles.value = articlesCount;
    apiSavedArticlesData.value = articlesData ?? [];
  } catch (error) {
    apiSavedArticlesData.value = [];
    totalNumOfArticles.value = 0;
  }

  containerRef.value.children[0].scrollIntoView({ behavior: "smooth" });
  loadingArticles.value = false;
});

const exportCSV = async () => {
  //appStore.isExporting = true;

  const id = guid.NewGuid();
  alertsStore.add({
    id: id,
    type: "toast",
    variant: "load",
    message: t("general.alerts.toast.downloadingCsv", {
      type: t("views.cluster.articles"),
    }),
  });

  const filters = queryObject.value.filters ?? [];
  if (!isEmpty(queryObject.value.contextualFilters))
    filters.push({ facets: queryObject.value.contextualFilters });

  const { data } = await api.search.savedArticlesCSV(
    viewId.value,
    dateRestriction.value,
    filters
  );
};
eventBus.on("export-saved-csv", exportCSV);
onUnmounted(() => {
  eventBus.off("export-saved-csv", exportCSV);
});
</script>

<style scoped lang="scss">
.m-container {
  width: 100%;
  height: calc(100vh - $header-height - $spacing-4);

  &__wrapper {
    width: 100%;
    height: 100%;
    @include flex(flex-start, flex-start, column);
  }

  &__load {
    overflow-y: hidden;
  }

  &__header {
    &--load {
      opacity: 0.6;
      filter: blur($spacing-1);
    }
  }
}

.m-saved {
  &__empty {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    @include flex(center, center, column);
    flex-grow: 1;
    transform: translateY(-50%);

    h6 {
      @include flex(flex-start, center, row);
    }
  }

  .m-article--list.m-article--saved {
    background-color: transparent;
  }
}
</style>
