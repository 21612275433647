<template>
  <div
    class="m-feed-link"
    :class="{
      '--has-link': isValidUrl,
      'm-feed-link--rtl': dir == 'rtl',
    }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click.stop="openFeed"
  >
    <h5 :class="`type--${size}`">
      {{ feed.handle ?? feed.name }}
    </h5>
    <m-icon
      v-if="isValidUrl"
      id="open_new_tab"
      :icon="feed.url && hover ? 'open-new-tab' : 'none'"
      size="small"
      status="active"
    />
  </div>
</template>

<script setup>
/*
 * Monitio MFeedLink component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/881950898/Feed+Link
 */

import { ref, computed } from "vue";
import MIcon from "@components/MIcon.vue";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const workspacesStore = useWorkspacesStore();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);

const props = defineProps({
  feed: { required: true, type: Object },
  dir: { type: String, default: "ltr" },
  size: {
    type: String,
    default: "default",
    validator(size) {
      if (!["default", "small"].includes(size)) {
        console.error(
          `Invalid prop "size": expected string with value "default" or "small" and got "${size}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/881950898/Feed+Link for instructions on how to use the MFeedLink component.`
        );
      }
      return true;
    },
  },
});

const computedSrc = computed(() => {
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  if (props.feed.url?.includes("blob-store://")) {
    const n = props.feed.url;
    const bucketName = props.feed.url.includes("account")
      ? "priberam"
      : workspaceConfig.value.workspaceBucketName;
    return n.replace(
      "blob-store://",
      `${window._env_.VUE_APP_apiUrl}/v1/workspaces/picture/${bucketName}/`
    );
  }
  return props.feed.url;
});

const isValidUrl = computed(() => {
  const pattern = ["http://", "https://", "blob-store://"];
  for (const item of pattern) {
    if (computedSrc.value?.includes(item)) return true;
  }
  return false;
});

const hover = ref(false);

const openFeed = () => {
  if (!isValidUrl.value) return;
  if (computedSrc.value) {
    let cleanLink = computedSrc.value?.split("?amp;utm_")[0];
    const hasParams = cleanLink.split("?")[1];

    if (hasParams) cleanLink = `${cleanLink}&`;
    else cleanLink = `${cleanLink}?`;

    window.open(`${cleanLink}utm_source=monitio`, "_blank");
  }
};
</script>

<style scoped lang="scss">
.m-feed-link {
  @include flex(flex-start, flex-start, row);

  &.--has-link {
    cursor: pointer;

    * {
      cursor: pointer;
    }

    h5,
    svg {
      fill: color($pri-action-inactive);
      color: color($pri-action-inactive);
      stroke: color($pri-action-inactive);
    }

    &:hover {
      h5,
      svg {
        fill: color($pri-action);
        color: color($pri-action);
        stroke: color($pri-action);
      }
    }
  }

  &--rtl {
    flex-direction: row-reverse;
  }

  h5,
  h6,
  &.m-type-skeleton__h6 {
    margin-right: $spacing-1;
    @include one-line-ellipsis;
  }
}
</style>
