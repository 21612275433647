import type { MonitioAPI } from "@root/types.api.local";
import TimeFrame from "@root/utils/enums/timeFrames";
import type { AxiosInstance, CancelTokenSource } from "axios";

const DEFAULT_DATERESTRICTION: MonitioAPI.DateRestriction = {
  timeFrame: (<unknown>TimeFrame.Today) as MonitioAPI.TimeFrame,
  isRelative: false,
};

export default (axios: AxiosInstance) => ({
  search(
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    first: number,
    last: number,
    filters: MonitioAPI.FrontendFiltersGroup[],
    sortBy: "relevance" | "date" = "relevance",
    mode: "read" | "unread" | "all" = "all",
    groupDuplicates: boolean
  ) {
    return axios.post("/v1/search/search", filters || [], {
      params: {
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
        first,
        last,
        mode,
        sortBy,
        groupDuplicates,
      },
    });
  },
  /** @description Exactly the same as the search request but the output is a CSV file */
  searchCSV(
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    first: number,
    last: number,
    filters: MonitioAPI.FrontendFiltersGroup[],
    sortBy: "relevance" | "date" = "relevance",
    mode: "read" | "unread" | "all" = "all",
    groupDuplicates: boolean
  ) {
    return axios.post("/v1/search/search-csv", filters || [], {
      params: {
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
        first,
        last,
        mode,
        sortBy,
        groupDuplicates,
      },
    });
  },
  denseDocumentSearch(document: MonitioAPI.SearchDocumentDTO) {
    return axios.post("/v1/search/dense-document-search", document);
  },
  /** @descprition Given a given duplicateId retrieve all items in that group */
  getDuplicates(viewId: string, articleId: string, duplicateId: string) {
    return axios.get("/v1/documents/duplicates", {
      params: {
        viewId,
        articleId,
        duplicateId,
      },
    });
  },
  entitywalk(
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    filters: MonitioAPI.FrontendFiltersGroup[],
    refineBy: string[],
    preference: string,
    graphType: string,
    displayIPTCTopics: string,
    forceCenterNode: string | null = null
  ) {
    return axios.post("/v1/mindMap/entity-walk", filters || [], {
      params: {
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
        preference,
        graphType,
        refineBy,
        displayIPTCTopics,
        forceCenterNode,
      },
    });
  },
  getDocument(viewId: string, articleId: string, alsoTeams = false) {
    return axios.get("/v1/documents", {
      params: {
        viewId: viewId,
        docId: articleId,
        alsoTeams: alsoTeams,
      },
    });
  },
  refineProperties(
    viewId: string,
    filters: MonitioAPI.FrontendFiltersGroup[],
    dateRestriction: MonitioAPI.DateRestriction = DEFAULT_DATERESTRICTION,
    property: string | null = null,
    itemsPerProperty = 6
  ) {
    // more params are incomingo from the function call
    return axios.post("/v1/search/refine-properties", filters || [], {
      params: {
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
        property,
        itemsPerProperty,
      },
    });
  },
  trendingEntities(
    viewId: string,
    granularity: string,
    dateRestriction: MonitioAPI.DateRestriction = DEFAULT_DATERESTRICTION,
    filters: MonitioAPI.FrontendFiltersGroup[],
    refineBy = ["people", "organizations", "places"]
  ) {
    return axios.post("/v1/trendingEntities", filters || [], {
      params: {
        viewId,
        refineBy,
        datesGranularity: granularity,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
      },
    });
  },
  trendingEntitiesTimeline(
    viewId: string,
    entityKey: string,
    entityType: string,
    dateRestriction: MonitioAPI.DateRestriction = DEFAULT_DATERESTRICTION,
    granularity: string,
    filters: MonitioAPI.FrontendFiltersGroup[]
  ) {
    // more params are incomingo from the function call
    return axios.post("/v1/trendingEntities/timeline", filters || [], {
      params: {
        viewId,
        entityKey,
        entityType,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
        datesGranularity: granularity,
        filters,
      },
    });
  },
  getTrendingExplanation(data: MonitioAPI.ExplainTrendingDTO) {
    return axios.post("/v1/trendingEntities/get-explanation", data);
  },
  getEntitiesConnectionExplanation(
    data: MonitioAPI.ExplainEntiiesConnectionDTO
  ) {
    return axios.post("/v1/mindMap/get-explanation", data);
  },
  getComplexEntitiesConnectionExplanation(
    data: MonitioAPI.EntityWalkNode[],
    dateRestriction: MonitioAPI.DateRestriction = DEFAULT_DATERESTRICTION
  ) {
    console.log(data, dateRestriction);

    return axios.post("/v1/mindMap/get-complex-explanation", data, {
      params: {
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
      },
    });
  },
  viewReader(viewid: string, mode: string, params: object) {
    return axios.get(`viewReader/${mode}`, {
      params: { viewId: viewid, ...params },
    });
  },
  updateDocumentReadStatus(
    mode: string,
    viewid: string,
    docid: string,
    index: string,
    params: object
  ) {
    // notice alsoTeam always = true; decision: all reads mark as all user teams
    // the reads depend on context
    return axios.put(
      `/v1/viewReader/${mode}?viewId=${viewid}&docid=${docid}&index=${index}&alsoTeam=true`,
      { params }
    );
  },
  requestViewReadAll(
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction = DEFAULT_DATERESTRICTION,
    filters: object
  ) {
    // notice alsoTeam always = true; decision: all reads mark as all user teams
    // the reads depend on context
    return axios.post("/v1/viewReader/read-all", {
      viewId,
      filters,
      dateRestriction: {
        isRelative: dateRestriction.isRelative,
        timeFrame: dateRestriction.timeFrame,
        start: dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        end: dateRestriction.end?.toISO?.() ?? dateRestriction.end,
      },
    });
  },
  autocomplete(
    viewid: string,
    segment: string,
    types: string,
    cancelToken: AbortController | undefined = undefined,
    doPropertyKeys = true,
    doSearchKeys = true,
    numPropertyKeys = 10,
    numSearchKeys = 10
  ) {
    return axios.get("/v1/search/autocomplete", {
      signal: cancelToken?.signal,
      params: {
        viewId: viewid,
        segment,
        propertyTypes: types,
        propertyKeys: doPropertyKeys,
        searchKeys: doSearchKeys,
        numPropertyKeys: numPropertyKeys,
        numSearchKeys: numSearchKeys,
      },
    });
  },
  topClusters(
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    aggregator: string,
    filters: MonitioAPI.FrontendFiltersGroup[],
    numberOfClusters = 50
  ) {
    // console.info(arguments);
    return axios.post("/v1/storylines/top-clusters", filters || [], {
      params: {
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
        numberOfClusters,
        aggregator,
      },
    });
  },
  cluster(
    viewId: string,
    clusterId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    aggregator: string,
    sortBy: string,
    first = 0,
    last = 4,
    filters: MonitioAPI.FrontendFiltersGroup[]
  ) {
    return axios.post("/v1/storylines/cluster", filters || [], {
      params: {
        viewId,
        clusterId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
        aggregator,
        sortBy,
        first,
        last,
      },
    });
  },
  clustersFeedCount(
    viewId: string,
    clusterIds: string,
    dateRestriction: MonitioAPI.DateRestriction,
    aggregator: string,
    first = 0,
    last = 4,
    filters: MonitioAPI.FrontendFiltersGroup[] = []
  ) {
    return axios.post("/v1/storylines/clustersFeedCount", filters || [], {
      params: {
        viewId,
        clusterIds,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
        aggregator,
        first,
        last,
      },
    });
  },
  summarizeDocument(language: string, body: MonitioAPI.SearchDocumentDTO) {
    return axios.post(
      `/v1/summarize/summarize-document?language=${language}`,
      body
    );
  },
  summarizeDocuments(language: string, body: MonitioAPI.SearchDocumentDTO[]) {
    return axios.post(
      `/v1/summarize/summarize-documents?language=${language}`,
      body
    );
  },
  savedArticles(
    viewid: string,
    dateRestriction: MonitioAPI.DateRestriction,
    filters: MonitioAPI.FrontendFiltersGroup[],
    first: number,
    last: number
  ) {
    return axios.post(`/v1/documents/${viewid}/saved`, dateRestriction, {
      params: {
        first,
        last,
      },
    });
  },
  savedArticlesCSV(
    viewid: string,
    dateRestriction: MonitioAPI.DateRestriction,
    filters: MonitioAPI.FrontendFiltersGroup[]
  ) {
    return axios.post(`/v1/documents/${viewid}/saved-csv`, filters, {
      params: {
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame?.valueOf(),
        "dateRestriction.reportId": dateRestriction.reportId,
        "dateRestriction.start":
          dateRestriction.start?.toISO?.() ?? dateRestriction.start,
        "dateRestriction.end":
          dateRestriction.end?.toISO?.() ?? dateRestriction.end,
      },
    });
  },
  saveArticle(viewId: string, docId: string, date: string) {
    return axios.put(
      `/v1/documents/${docId}/save?viewId=${viewId}&docDate=${date}`
    );
  },
  unsaveArticle(viewid: string, docId: string, index: string) {
    return axios.delete(
      `/v1/documents/${docId}/saved?viewId=${viewid}&index_name=${index}`
    );
  },
});
