import { inject, reactive, readonly } from "vue";
import { useApi } from "@api/api";
import structuredClone from "@utils/structuredClone";
import { defineStore } from "pinia";
import type { AxiosResponse } from "axios";
import type { MonitioAPI } from "@root/types.api.local";

interface ReportsStoreState {
  moduleHasInit: boolean;
  list: MonitioAPI.ReportDTO[];
  templates: MonitioAPI.ReportTemplateDTO[];
  templateDrafts: ReportTemplate[];
  shared: MonitioAPI.ReportDTO[];
}

interface ReportTemplate extends MonitioAPI.ReportTemplateDTO {
  isDraft: boolean;
}

export const useReportsStore = defineStore("reports", {
  state: (): ReportsStoreState => ({
    moduleHasInit: false,
    list: [],
    shared: [],
    templates: [],
    templateDrafts: [],
  }),

  getters: {
    getById() {
      return (
        id: string | null | undefined
      ): MonitioAPI.ReportDTO | Report | null => {
        if (!id) return null;
        const report = this.list.find((x) => x.id == id);
        if (report) return report;
        return null;
      };
    },
    getListByViewId() {
      return (
        id: string | null | undefined
      ): MonitioAPI.ReportDTO[] | Report[] | null => {
        if (!id) return null;
        const reports = this.list.filter((x) => x.viewId == id);
        if (reports) return reports;
        return null;
      };
    },
    getTemplateById() {
      return (
        id: string | null | undefined
      ): MonitioAPI.ReportTemplateDTO | ReportTemplate | null => {
        if (!id) return null;
        const report = this.templates.find((x) => x.id == id);
        if (report) return report;
        const draft = this.templateDrafts.find((x) => x.id == id);
        if (draft) {
          draft.isDraft = true;
          return draft;
        }
        return null;
      };
    },
    getNameById() {
      return (id: string | null | undefined) => {
        if (!id) return null;
        const report = this.list.find((x) => x.id == id);
        const template = this.templates.find(
          (x) => x.id == report?.template?.id
        );
        if (template) return template?.template?.title;
        return null;
      };
    },
    getAll: (state) => [...state.list, ...state.shared],
    getAllTemplates: (state) => state.templates,
  },

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Reports module already initialized");
        return;
      }

      await Promise.all([
        this.getRemoteReports(),
        this.getRemoteReportTemplates(),
      ]);

      this.moduleHasInit = true;
    },
    async shutdown() {
      this.list = this.templateDrafts = this.shared = [];
      this.moduleHasInit = false;
    },
    async getRemoteReports() {
      const { api } = useApi();
      try {
        const reports = await api.reports.getAll();
        this.list = reports.data;
      } catch {
        this.list = [];
      }
    },
    async getRemoteReportTemplates() {
      const { api } = useApi();
      try {
        const templates = await api.reports.getAllTemplates();
        this.templates = templates.data;
      } catch {
        this.templates = [];
      }
    },
    async getRemoteReportTemplate(id: string) {
      const { api } = useApi();
      try {
        const { data } = (await api.reports.getReporTemplatetById(
          id
        )) as AxiosResponse<MonitioAPI.ReportTemplateDTO>;
        const idx = this.templates.findIndex((x) => x.id == data.id);
        if (idx >= 0) this.templates[idx] = data;
      } catch {
        console.debug("Couldnt fetch template");
      }
    },
    async getDetails(id: string) {
      const { api } = useApi();
      return await api.reportsmodule.template(id);
    },
    async saveTemplate(
      template: ReportTemplate,
      viewId: string,
      dateRestriction: MonitioAPI.DateRestriction
    ) {
      const { api } = useApi();
      //Clean up the report from any unwanted data on any dashboard component that exists
      const cReportTemplate = structuredClone(template) as ReportTemplate;
      cReportTemplate.template?.modules?.forEach((module) => {
        if (module.module == "rmDashboards") {
          module.settings?.dashboard?.elements?.forEach((element: any) => {
            if (element.data) delete element.data;
          });
        }

        if (module.settings?.dateRestriction === null) {
          // TO avoid problems the report always must be saved with some dateRestricion
          module.settings.dateRestriction = dateRestriction;
        }
      });

      try {
        const { request, data } = (
          template.isDraft
            ? await api.reports.createTemplate(cReportTemplate)
            : await api.reports.updateTemplate(cReportTemplate)
        ) as AxiosResponse<MonitioAPI.ReportTemplateDTO>;
        if (request.status != 200) return null;
        const idx = this.templates?.findIndex((f) => f.id === data.id);
        if (idx < 0) {
          const draftIdx = this.templateDrafts.findIndex(
            (x) => x.id == template.id
          );
          this.templateDrafts.splice(draftIdx, 1);
          cReportTemplate.isDraft = false;
          this.templates?.push(cReportTemplate);
        } else {
          this.templates[idx] = { ...this.templates[idx], ...cReportTemplate };
        }

        if (!data.reports) return;

        for (let idx = this.list.length - 1; idx >= 0; idx--) {
          const report = this.list[idx];
          //Remove the necessary reports

          if (report.template?.id != template.id) continue;
          if (!data.reports.some((x) => x.id == report.id)) {
            this.list.splice(idx, 1);
          }
        }
        for (const report of data.reports) {
          //Add the necessary reports
          const idx = this.list.findIndex((x) => x.id == report.id);
          if (idx >= 0) this.list[idx] = report;
          else this.list.push(report);
        }
      } catch (error) {
        console.error("Couldnt save report template");
      }
    },
    async setTemplate(template: ReportTemplate) {
      const dIdx = this.templateDrafts.findIndex((x) => x.id == template.id);
      if (dIdx >= 0) {
        this.templateDrafts[dIdx] = template;
      }
      const idx = this.templates.findIndex((x) => x.id == template.id);
      if (idx >= 0) {
        this.templates[idx] = template;
      }
    },
    async upsertSnapshot(reportId: string, snapshot: MonitioAPI.SnapshotDTO) {
      const report = this.list.find((f) => f.id === reportId);
      if (report) {
        if (!Array.isArray(report.snapshots)) report.snapshots = [];

        const idx = report.snapshots.findIndex((f) => f.id === snapshot.id);
        if (idx >= 0) report.snapshots.splice(idx, 1, snapshot);
        else report.snapshots.splice(0, 0, snapshot);
      }
    },
    async loadSnapshot({
      reportId,
      snapshotId,
    }: {
      reportId: string;
      snapshotId: string;
    }) {
      const { api } = useApi();

      const report = this.list.find((f) => f.id === reportId);
      if (!report) throw `Report ${reportId} not found`;

      const snapshot = report.snapshots?.find((f) => f.id === snapshotId);
      if (!snapshot) throw `Snapshot ${snapshotId} not found`;

      if (snapshot) return snapshot;
      else {
        const response = await api.reports.snapshot(reportId, snapshotId);
        this.upsertSnapshot(reportId, response.data);
        return response.data;
      }
    },
    async takeSnapshot(report: MonitioAPI.ReportDTO) {
      const { api } = useApi();
      const response = await api.reports.createSnapshot(report, false);
      if (!report?.id) return;
      this.upsertSnapshot(report.id, {
        ...response.data,
        snapshot: report.snapshots,
      });
      return response.data;
    },
    async sendSnapshot(report: MonitioAPI.ReportDTO) {
      const { api } = useApi();

      const response = await api.reports.createSnapshot(report, true);
      if (!report?.id) return;
      await this.upsertSnapshot(report.id, {
        ...response.data,
        snapshot: report.snapshots,
      });
      return response.data;
    },
    async automateReport(
      settings: MonitioAPI.ReportAutomateSettings,
      id: string
    ) {
      const { api } = useApi();
      try {
        const report = this.list.find((x) => x.id == id);
        if (!report) throw new Error("Unable to find report");

        await api.reports.automate(settings, id);

        report.recurrencyInfo = settings;
      } catch (error) {
        console.error(error);
      }
    },
    async updateReport(report: MonitioAPI.ReportDTO) {
      const { api } = useApi();
      try {
        const reportIdx = this.list.findIndex((x) => x.id == report.id);
        if (!report) throw new Error("Unable to find report");
        await api.reports.update(report);

        this.list[reportIdx] = report;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteTemplate(reportTemplateId: string) {
      const { api } = useApi();

      if (!this.templateDrafts.some((x) => x.id == reportTemplateId))
        await api.reports.deleteTemplate(reportTemplateId);

      [this.templateDrafts, this.templates].forEach((list) => {
        const idx = list.findIndex((f) => f.id === reportTemplateId);
        if (idx >= 0) list.splice(idx, 1);
      });

      this.list = this.list.filter((x) => x.template?.id != reportTemplateId);
    },
    async createDraft(template: ReportTemplate) {
      this.templateDrafts.push(template);
    },
    /*  async select(reportTemplateId) {
      this.current = this.list?.find((x) => x.id == reportTemplateId) || {};
    }, */
  },
});
