/**
 * File with common code for sharing between article related views and components
 */

import DateTimeUtils from "@utils/dateTime";
import TimeFrame from "@utils/enums/timeFrames";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import structuredClone from "@utils/structuredClone";
import { DateTime, Interval } from "luxon";
import { debounce } from "lodash-es";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useReportsStore } from "@root/store/modules/reports";
import { useFiltersStore } from "@root/store/modules/filters";

let route;
let router;

//#region Query Object related stuff. This needs to be in a global context, so all the components communicate with the same debounce
/**
 * @description The object that will be used as prototype for queryObject. This allows the eaiser use of these methods like for example: queryObject.clone() or queryObject.addFilter()
 * NOTE: These methods will only be available after using queryObject.clone()
 */
const queryObjectUtils = {};

/**
 * @description The decoded view query from the url parameters
 * @type {import("vue").ComputedRef<import("@root/types").Monitio.URLQueryObject>}
 */
const queryObject = computed({
  get() {
    const obj = route.query.q ? decodeQueryObject(route.query.q) : {};
    Object.setPrototypeOf(obj, {
      clone: function () {
        const qObj = structuredClone(queryObject.value);
        if (queryObject.value.dateRestriction)
          qObj.dateRestriction = queryObject.value.dateRestriction;
        Object.setPrototypeOf(qObj, queryObjectUtils);
        return qObj;
      },
    });
    return obj;
  },
  /** @param {import("@root/types").Monitio.URLQueryObject} viewQuery */
  set(viewQuery) {
    const params = {
      name: route.name,
      params: route.params,
    };
    if (areFiltersEmpty(viewQuery.filters)) delete viewQuery.filters;
    if (Object.keys(viewQuery).length > 0)
      params.query = { ...route.query, q: encodeQueryObject(viewQuery) };
    router.push(params);
  },
});

/**
 * @description Simple wrapper function with a debounce for the queryObect setter
 * @param {import("@root/types").Monitio.URLQueryObject} viewQuery
 */
const updateQueryObject = debounce((val) => {
  queryObject.value = val;
}, window._env_.VUE_APP_DEFAULT_FILTER_DEBOUNCE);

/**
 * @description Function that checks if the filter objet is empty
 * @type {import("vue").ComputedRef<import("@root/types").Monitio.URLQueryObject>}
 */
const areFiltersEmpty = (filters) => {
  if (!filters) return true;
  if (filters.every((x) => !x.facets?.length)) return true;
  return false;
};

/**
 * @description Provide a route and an encoded or decoded URLQueryObject and it navigates to that route with the queryObject attached
 * @param {import("@root/types").Monitio.URLQueryObject} query
 * @param {string} routeName The route name
 * @param {object} params
 */
const navigateWithQueryObject = (query, routeName, params, meta = null) => {
  if (typeof query != "object")
    throw new Error("Filter object must be an object!");
  if (query.dateRestriction) {
    //make sure the dateResctrition is in JSDate format to avoid issues
    if (DateTime.isDateTime(query.dateRestriction.start))
      query.dateRestriction.start = query.dateRestriction.start.toJSDate();
    if (DateTime.isDateTime(query.dateRestriction.end))
      query.dateRestriction.end = query.dateRestriction.end.toJSDate();
  }
  //updateQueryObject(query);
  router.push({
    name: routeName,
    params: params,
    query: { ...route.query, q: encodeQueryObject(query) },
    meta,
  });
};

/**
 * @param {string} q A base64 string that lives no the browser url query
 * @returns {import("@root/types").Monitio.URLQueryObject} the decoded and parsed object
 */
const decodeQueryObject = (q) => {
  if (!q) return {};
  /** @type {import("@root/types").Monitio.URLQueryObject} */
  const query = JSON.parse(decodeURIComponent(escape(window.atob(q))));
  /** Before returning we need to convert the datetimes back into luxon Datetime
   *  Because they get conefrted into a specific string format */
  if (query.dateRestriction && !query.dateRestriction.isRelative) {
    query.dateRestriction.start = DateTimeUtils.parseFromISO(
      query.dateRestriction.start
    );
    query.dateRestriction.end = DateTimeUtils.parseFromISO(
      query.dateRestriction.end
    );
  }
  return query;
};

/**
 * @description Function that returns a ready to go object to send to the backend as parameter
 * @param {import("vue").ComputedRef<import("@root/types").Monitio.URLQueryObject>} query A base64 string that lives no the browser url query
 * @returns {Monitio.DTOs.BackendQueryObjectDTO} the decoded and parsed object
 */
const convertToBackendQuery = (query) => {
  /** @type {Monitio.DTOs.BackendQueryObjectDTO} */
  const res = {};
  if (query.dateRestriction)
    res.daterestriction = formatDateRestriction(query.dateRestriction);
  /* if (query.filters) {
    const { filters, customQuery } = formatFilters(query.filters);
    res.filters = filters;
    res.customQuery = customQuery;
  } */
  return res;
};

/** @param {import("vue").ComputedRef<import("@root/types").Monitio.URLQueryObject>} viewQuery */
const encodeQueryObject = (viewQuery) => {
  const b64 = btoa(unescape(encodeURIComponent(JSON.stringify(viewQuery))));
  return b64;
};
//#endregion

//#region Utils functions
/**
 * @description Function that transforms the query.dateRestriction into a properly formatted string for the backend to receive
 * stored at query.daterestriction
 * @param {import("vue").ComputedRef<import("@root/types").Monitio.URLQueryObject>} query The query object that is retrieved through decodeQueryObject or the computed variable queryObject
 * @returns {string}
 */
const formatDateRestriction = (dateRestriction) => {
  const userStore = useUserStore();
  const timeZone = userStore.timeZone;
  /** Grab the date (if relative) and convert the timeframe a an absolute date */
  if (dateRestriction && dateRestriction.isRelative) {
    const reportId = route.params.reportId;
    const reportStore = useReportsStore();

    const report = reportStore.getById(reportId);
    const absoluteDate = DateTimeUtils.getTimeFrame(
      TimeFrame.TryParseFromString(dateRestriction.timeFrame, timeZone, report)
    );
    return DateTimeUtils.createDateRestriction(
      absoluteDate.start,
      absoluteDate.end,
      true
    );
  } else if (dateRestriction && !dateRestriction.isRelative) {
    return DateTimeUtils.createDateRestriction(
      DateTimeUtils.parseFromISO(dateRestriction.start),
      DateTimeUtils.parseFromISO(dateRestriction.end),
      true
    );
  }
};

/**
 * @description Function that transforms the facets into a properly formatted string for the backend to receive
 * @deprecated Check after MONITIO USERDAY. The filters now can be sent as is. The backend will take care of the rest
 * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[] | undefined} fg (facetGroups) The query object that is retrieved through decodeQueryObject or the computed variable queryObject
 * @returns {{customQuery: string, filters :import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[]}}
 */
/* const formatFilters = (fg) => {
  if (!fg) return { customQuery: "", filters: [] };
  /** @type {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[]}
  const facetGroups = structuredClone(fg);
  let customQuery = null;
  facetGroups.forEach((facetGroup) => {
    facetGroup.customQuery = "";

    // Handle the customQueries here, if any;
    const facet = facetGroup.facets?.filter(
      (x) => x.value == "customQuery"
    )?.[0];

    const queryValues = facet?.query;
    if (!queryValues) facetGroup.customQuery = "";
    else {
      queryValues.forEach((item, idx) => {
        if (idx == queryValues.length - 1 && item.operator) return;
        if (item.operator)
          facetGroup.customQuery += item.operator == "and" ? " + " : " | ";
        else if (item.value) {
          if (item.negated) facetGroup.customQuery += "-";
          facetGroup.customQuery += `(${item.value})`;
        }
      });
    }

    if (!customQuery) customQuery = facetGroup?.customQuery;

  });

  // Return the 1st customquery that was fund in all the froups by default. Also return the correctly formated filters
  return { customQuery, filters: facetGroups ?? [] };
}; */
//#endregion

/**
 * @param {Router} _router
 * @param {RouteLocationNormalizedLoaded} _route
 */
export const useViewFilters = (_router, _route) => {
  if (!route) route = _route;
  if (!router) router = _router;

  const { t, te } = useI18n();
  const viewsStore = useViewsStore();
  const userStore = useUserStore();
  const filtersStore = useFiltersStore();
  const workspacesStore = useWorkspacesStore();

  /** @type {import("@root/types.api.local").MonitioAPI.WorkspaceDTO} */
  const workspace = workspacesStore.currentWorkspaceConfig;

  //#region sort ⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅
  const sortBy = computed({
    get() {
      //return viewsStore.aggregator; Need to verifify if we want this in the store
      const { sortBy } = queryObject.value;
      if (sortBy) return sortBy;
      return window._env_.VUE_APP_DEFAULT_SORT ?? "relevance";
    },
    set(val) {
      updateSortBy(val);
    },
  });
  const updateSortBy = (val) => {
    const query = { ...queryObject.value };
    if (!val) {
      delete query.sortBy;
    } else {
      query.sortBy = val;
    }
    updateQueryObject(query);
  };
  //#endregion

  //#region layout ⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅
  const layout = computed(() => viewsStore.layout);
  const changeLayout = (val) => {
    viewsStore.setLayout(val);
  };
  //#endregion

  //#region snippet ⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅
  const showSnippet = computed(() => viewsStore.showSnippet);
  const changeShowSnippet = (val) => {
    viewsStore.setShowSnippet(val);
  };
  //#endregion

  //#region aggregate duplicates ⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅
  const aggregateDuplicates = computed({
    get() {
      //return viewsStore.aggregator; Need to verifify if we want this in the store
      const { aggregateDuplicates } = queryObject.value;
      if (aggregateDuplicates == null || aggregateDuplicates == undefined)
        return true;
      return aggregateDuplicates;
    },
    set(val) {
      updateAggregateDuplicates(val);
    },
  });
  const updateAggregateDuplicates = (val) => {
    const query = { ...queryObject.value };
    if (val === null || val === undefined) {
      delete query.aggregateDuplicates;
    } else {
      query.aggregateDuplicates = val;
    }
    updateQueryObject(query);
  };
  //#endregion

  //#region show ⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅
  const show = computed(() => viewsStore.show);
  const changeShow = (val) => {
    viewsStore.setShow(val.value);
    /*  push({
      ...currentRoute.value,
      query: { ...currentRoute.value.query, m: val },
    }); */
  };
  //#endregion

  //#region Aggregator
  const DEFAULT_AGGREGATOR = window._env_.VUE_APP_DEFAULT_AGGREGATOR;
  const aggregator = computed({
    get() {
      //return viewsStore.aggregator; Need to verifify if we want this in the store
      const { aggregator } = queryObject.value;
      if (aggregator) return aggregator;

      //Check the backend for defaults
      const workspaceId = workspacesStore.id;
      const values = userStore.config.propertyTypeSettings?.storylines?.[
        workspaceId
      ]
        .filter((x) => x.active && x.selected)
        ?.sort((a, b) => a.rank - b.rank);

      if (!values)
        //If everthring goes wrong return the default aggregator
        return DEFAULT_AGGREGATOR;

      if (
        values?.length > 0 &&
        values.map((x) => x.searchKey).includes(DEFAULT_AGGREGATOR)
      ) {
        return DEFAULT_AGGREGATOR;
      } else {
        return values[0]?.searchKey;
      }
    },
    set(val) {
      updateAggregator(val);
    },
  });

  const updateAggregator = (val) => {
    const query = { ...queryObject.value };
    if (!val) {
      delete query.aggregator;
    } else {
      query.aggregator = val;
    }
    updateQueryObject(query);
    // viewsStore.setAggregator(val); Need to verifify if we want this in the store
  };

  const aggregatorOpts = computed(() => {
    const workspaceId = workspacesStore.id;
    return userStore.config.propertyTypeSettings?.storylines?.[workspaceId]
      ?.filter((x) => x.active && x.appearsOnSection?.includes("storylines"))
      .map((x) => ({
        value: x.searchKey,
        label: te(`general.facets.${x.uiKey}`)
          ? t(`general.facets.${x.uiKey}`)
          : x.uiKey,
      }));
  });
  //#endregion

  //#region RefineBy
  const refineBy = computed({
    get() {
      const { refineBy } = queryObject.value;
      if (refineBy) return refineBy;

      // DEFAULT_REFINEBY
      let result;
      if (route.name == "trending-entities") {
        result =
          userStore.config?.propertyTypeSettings?.trending?.[workspace.id];
      } else if (route.name == "entity-network") {
        result =
          userStore.config?.propertyTypeSettings?.mindmap?.[workspace.id];
      }

      result =
        result?.filter((x) => x.active && x.selected).map((m) => m.searchKey) ??
        [];

      if (result?.length > 0) return result;
      return DEFAULT_REFINEBY;
    },
    set(val) {
      updateRefineBy(val);
    },
  });

  const updateRefineBy = (val) => {
    const query = { ...queryObject.value };
    if (!val) {
      delete query.refineBy;
    } else {
      query.refineBy = val;
    }
    updateQueryObject(query);
  };

  const refineByTrendingOpts = computed(() => {
    const workspaceId = workspacesStore.id;
    const userWorkspaceDetails =
      userStore.config?.propertyTypeSettings?.trending?.[workspaceId].filter(
        (x) => x.active
      );

    if (userWorkspaceDetails) {
      return userWorkspaceDetails
        .map((x) => ({
          value: x.searchKey,
          label: te(`general.facets.${x.uiKey}`)
            ? t(`general.facets.${x.uiKey}`)
            : x.uiKey,
          selected: x.selected,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [{ value: "iptc", label: t("general.facets.IPTC") }];
  });

  const refineByMindmapOpts = computed(() => {
    const workspaceId = workspacesStore.id;
    const userWorkspaceDetails =
      userStore.config?.propertyTypeSettings?.mindmap?.[workspaceId].filter(
        (x) => x.active
      );

    if (userWorkspaceDetails) {
      return userWorkspaceDetails
        .map((x) => ({
          value: x.searchKey,
          label: te(`general.facets.${x.uiKey}`)
            ? t(`general.facets.${x.uiKey}`)
            : x.uiKey,
          selected: x.selected,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [{ value: "iptc", label: t("general.facets.IPTC") }];
  });
  //#endregion

  //#region ViewId
  const viewId = computed({
    get() {
      const { viewId } = queryObject.value;
      if (viewId) return viewId;
      return null;
    },
    set(val) {
      updateViewId(val);
    },
  });

  const updateViewId = (val) => {
    const query = { ...queryObject.value };
    if (!val) {
      delete query.viewId;
    } else {
      query.viewId = val;
    }
    updateQueryObject(query);
  };
  //#endregion

  //#region Fiters
  const filters = computed(() => viewsStore.filters);

  const changeFilters = (val) => {
    viewsStore.changeFilters(val);
  };
  //#endregion

  //#region preference
  const preference = computed({
    get() {
      const { preference } = queryObject.value;
      if (preference) return preference;
      return DEFAULT_PREFERENCE;
    },
    set(val) {
      updatePreference(val);
    },
  });

  const updatePreference = (preference) => {
    const query = { ...queryObject.value };
    if (!preference) {
      delete query.preference;
    } else {
      query.preference = preference;
    }
    updateQueryObject(query);
  };
  //#endregion

  //#region graphType //ONLY FOR ENTITY NETWORK
  const centerNode = computed({
    get() {
      const { centerNode } = queryObject.value;
      if (centerNode) return centerNode;
      return null;
    },
    set(val) {
      updateCenterNode(val);
    },
  });

  const updateCenterNode = (centerNode) => {
    const query = { ...queryObject.value };
    if (!centerNode) {
      delete query.centerNode;
    } else {
      query.centerNode = centerNode;
    }
    updateQueryObject(query);
  };
  //#endregion

  //#region graphType //ONLY FOR ENTITY NETWORK
  const graphType = computed({
    get() {
      const { graphType } = queryObject.value;
      if (graphType) return graphType;
      return DEFAULT_GRAPHTYPE;
    },
    set(val) {
      updateGraphType(val);
    },
  });

  const updateGraphType = (graphType) => {
    const query = { ...queryObject.value };
    if (!graphType) {
      delete query.graphType;
    } else {
      query.graphType = graphType;
    }
    updateQueryObject(query);
  };
  //#endregion

  //#region granularity ⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅⛅
  /**@type {import("vue").ComputedRef<luxon.DateTimeUnit>} */
  const granularity = computed({
    get() {
      const { granularity } = queryObject.value;
      if (granularity) return granularity;
      return DEFAULT_GRANULARITY;
    },
    set(val) {
      updateGranularity(val);
    },
  });
  const updateGranularity = (granularity) => {
    const query = { ...queryObject.value };
    if (!granularity) {
      delete query.granularity;
    } else {
      query.granularity = granularity;
    }
    updateQueryObject(query);
  };

  const granularityOpts = computed(() => {
    const options = getAvailableGranularityOpts(dateRestriction.value);

    return (
      options?.map((option) => ({
        value: option,
        label: t(`navigation.header.filters.granularity_${option}`),
      })) || []
    );
  });

  /**
   * @description returns the granularity options that are available for the timeframe given. This has a limit beucase of the shear amount of data if we have a huge timeframe with a low granularity
   * @param { import("@root/types.api.local").MonitioAPI.DateRestriction } dateresctrition
   * @returns {string[]}
   */
  const getAvailableGranularityOpts = (dateresctrition) => {
    const userStore = useUserStore();
    const timeZone = userStore.timeZone;
    let absoluteDate = dateresctrition;

    if (dateresctrition.isRelative) {
      const reportId = dateresctrition.reportId;
      const reportStore = useReportsStore();

      const report = reportStore.getById(reportId);
      absoluteDate = DateTimeUtils.getTimeFrame(
        dateresctrition.timeFrame,
        timeZone,
        workspace.documentDateRange,
        report
      );
    }
    const options = [];

    /** @type {import("luxon").DateTime} */
    const dE =
      absoluteDate.end instanceof DateTime
        ? absoluteDate.end
        : DateTime.fromJSDate(absoluteDate.end);
    /** @type {import("luxon").DateTime} */
    const dS =
      absoluteDate.start instanceof DateTime
        ? absoluteDate.start
        : DateTime.fromJSDate(absoluteDate.start);

    options.push("day");
    if (Math.abs(dE.diff(dS, "weeks").weeks) > 1) options.push("week");
    if (Math.abs(dE.diff(dS, "months").months) > 1) options.push("month");
    /*     if (Math.abs(dE.diff(dS, "months").months) > 3)
      options = options.filter((x) => x != "day"); */
    if (Math.abs(dE.diff(dS, "years").years) > 1) {
      //options = options.filter((x) => x != "week");
      options.push("year");
    }
    /*if (Math.abs(dE.diff(dS, "years").years) > 3)
      options = options.filter((x) => x != "month"); */

    return options;
  };

  //#endregion

  //#region Date Restriction functionality

  /**
   * This computed provides teh default if needed, and makes it easy to set the dateResctrition
  /* @type { import("vue").ComputedRef<import("@root/types.api.local").MonitioAPI.DateRestriction>}
  */
  const dateRestriction = computed({
    get() {
      /** Check the query for the date parameter */
      const { dateRestriction } = queryObject.value;
      if (dateRestriction) return dateRestriction;

      /** If there's not a currently selected restrcition, output the defaults: */
      const newwestDocumentDate = DateTimeUtils.parseFromISO(
        workspace?.documentDateRange?.newest ?? DateTime.utc()
      );
      const oldesttDocumentDate =
        workspace?.documentDateRange?.oldest ??
        DateTime.utc().minus({ week: 1 });
      const workspaceDateRestriction =
        workspace?.metadata?.defaultDateRestriction ?? DEFAULT_DATERESTRICTION;

      const { start, end } = DateTimeUtils.getTimeFrame(
        workspaceDateRestriction.timeFrame,
        userStore.timeZone
      );
      const interval = Interval.fromDateTimes(
        oldesttDocumentDate,
        newwestDocumentDate.endOf("day").plus({ day: 1 })
      );

      if (!interval.contains(start) || !interval.contains(end)) {
        //This means this workspace either is an archive with old articles, or its not update to date, either way we must not return the today relative dateRestriction
        return {
          isRelative: true,
          timeFrame: TimeFrame.Latest.value,
        };
        // eslint-disable-next-line no-unreachable
        if (route.name == "trending-entities") {
          return {
            isRelative: false,
            start: newwestDocumentDate.startOf("day").minus({ days: 7 }),
            end: newwestDocumentDate.endOf("day"),
          };
        } else {
          return {
            isRelative: false,
            start: newwestDocumentDate.startOf("day"),
            end: newwestDocumentDate.endOf("day"),
          };
        }
      } else {
        return workspaceDateRestriction;
      }
    },
    set(val) {
      updateDateRestriction(val);
    },
  });

  const updateDateRestriction = (value) => {
    const query = structuredClone(queryObject.value);
    if (value instanceof TimeFrame) {
      query.dateRestriction = {
        isRelative: true,
        timeFrame: value.value,
      };
    } else if (value instanceof Object) {
      if (value.isRelative && value.timeFrame) {
        query.dateRestriction = { ...value };
      }
      if (!value.isRelative && value.start && value.end) {
        query.dateRestriction = { ...value };
      }
    } else {
      throw new Error(
        "The date format is not recognized as relative or absolute."
      );
    }

    query.dateRestriction.reportId = route.params.reportId;
    const options = getAvailableGranularityOpts(query.dateRestriction);
    //Override the granularity else monitio will crash the browser because its to much data
    query.granularity = options.pop();

    updateQueryObject(query);
  };
  //#endregion

  //#region Filters
  /**
   * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[]} filters
   * @description ATTENTION: This function REPLACES all the filters. To get
   * the current filters use queryObject.filters. Do your thing and then update
   */
  const updateViewFilters = (filters) => {
    const query = { ...queryObject.value };
    query.filters = filters;

    if (!query.filters || query.filters?.length == 0) delete query.filters;
    updateQueryObject(query);
  };

  /**
   * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[]} filterGroups
   * @description Function that returns the same filter array but withtou any malformatting errors that might exists
   * @returns {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup[]}
   */
  const rectifyViewFilters = (filterGroups) => {
    for (const filterGroup of filterGroups) {
      if (!filterGroup.facets) continue;
      for (const facet of filterGroup.facets) {
        if (!facet.query) continue;
        //The last item should not ever be an operator.Remove it if so
        if (facet.query.at(-1)?.operator) facet.query.pop();
      }
    }
    return filterGroups;
  };
  //#endregion

  //#region DEFAULT VALUES
  /**
   * @description Default value for the components that use relative/absolutes dates,
   * like for example the date selector in the subheader, and all the pages that need to query
   * the backend
   * @description This default must match with the one in the searchmodule in the API
   * @type {Monitio.DateRestriction}
   */
  const DEFAULT_DATERESTRICTION = {
    isRelative: true,
    timeFrame: TimeFrame.Today.value,
  };

  const DEFAULT_TRENDING_ENT_DATERESTRICTION = {
    isRelative: true,
    timeFrame: TimeFrame.Last7Days.value,
  };

  const DEFAULT_GRANULARITY = window._env_.VUE_APP_DEFAULT_GRANULARITY;
  const DEFAULT_PREFERENCE = window._env_.VUE_APP_DEFAULT_PREFERENCE;
  const DEFAULT_GRAPHTYPE = window._env_.VUE_APP_DEFAULT_GRAPHTYPE;
  const DEFAULT_REFINEBY = window._env_.VUE_APP_DEFAULT_REFINEBY;

  //#endregion

  return {
    show,
    sortBy,
    layout,
    showSnippet,
    viewId,
    filters,
    refineBy,
    refineByTrendingOpts,
    refineByMindmapOpts,
    graphType,
    centerNode,
    preference,
    aggregator,
    granularity,
    granularityOpts,
    queryObject,
    dateRestriction,
    aggregatorOpts,
    aggregateDuplicates,
    DEFAULT_AGGREGATOR,
    DEFAULT_DATERESTRICTION,
    changeShow,
    changeLayout,
    changeShowSnippet,
    changeFilters,
    /* formatFilters, */
    updateGraphType,
    updatePreference,
    updateAggregator,
    updateCenterNode,
    decodeQueryObject,
    encodeQueryObject,
    updateViewFilters,
    updateGranularity,
    rectifyViewFilters,
    formatDateRestriction,
    convertToBackendQuery,
    updateDateRestriction,
    navigateWithQueryObject,
    updateQueryObject,
  };
};

export class ViewFiltersUtils {
  /**
   * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup_FrontendFiltersFacet[]} f
   * @param {any} value
   * @returns
   */
  static async selectFilter(f, value) {
    console.log(value);
    if (!value) return;
    /** @type {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup_FrontendFiltersFacet[]} */
    const filters = f ? structuredClone(f) : [];

    const selectedFilterGroup = filters.find(
      (group) => group.value == value.filter
    );

    if (selectedFilterGroup) {
      const facet = selectedFilterGroup.query.find(
        (f) => f.value == value.value
      );
      if (facet) {
        const groupIdx = filters.findIndex(
          (group) => group.value == value.filter
        );
        const idx = selectedFilterGroup.query.findIndex(
          (f) => f.value == value.value
        );

        if (value.pane && !value.selected && !value.negated) {
          this.removeFilter(filters, groupIdx, idx);
        } else if (
          (!value.negated && !facet.negated) ||
          value.negated == facet.negated
        ) {
          // Do nothing
        } else {
          this.toggleNegated(filters, value.value, groupIdx, idx);
        }
      } else {
        const rowOperator = this.checkQueryOperator(selectedFilterGroup.query);
        if (value.negated) {
          selectedFilterGroup.query.unshift(
            {
              label: value.label,
              value: value.value,
              propertyType: value.filter,
              negated: value.negated ?? false,
            },
            {
              operator: "and",
            }
          );
        } else {
          if (!selectedFilterGroup.query.slice(-1)?.[0].operator) {
            /** Safe measure here to avoid bugs. Check if the last item of the array is an operator. If it isn't add it. The last item of the aray must always be an operator */
            selectedFilterGroup.query.push({
              operator: rowOperator,
            });
          }
          selectedFilterGroup.query.push({
            label: value.label,
            value: value.value,
            propertyType: value.filter,
            negated: value.negated ?? false,
          });
        }
        /* selectedFilterGroup.query.push({
        operator: rowOperator,
      }); */
      }
    } else {
      filters.push({
        value: value.filter,
        label: value.filterUiKey ?? value.filter,
        query: [
          {
            label: value.label,
            value: value.value,
            propertyType: value.filter,
            negated: value.negated ?? false,
          },
          {
            // If it is a collection of custom queries by default we put an AND
            operator: value.filter == "customQuery" ? "and" : "or",
          },
        ],
      });
    }

    /** This section handles hacks the object because of some edge cases with the negation */
    if (this.areAllNegated(filters, value.filter)) {
      filters
        ?.find((group) => group.value == value.filter)
        .query.forEach((item) => {
          if (item.operator) item.operator = "and";
        });
    }
    return filters;
  }

  static removeFilter(filters, groupIdx, idx) {
    if (filters[groupIdx]?.query?.length <= 2) {
      this.removeFilterGroup(filters, groupIdx);
    } else {
      filters[groupIdx]?.query?.splice(idx, 2);
    }
    return filters;
  }

  static removeFilterGroup(filters, groupIdx) {
    filters.splice(groupIdx, 1);
    return filters;
  }

  static removeAllFilters(filters) {
    filters.length = 0;
    return filters;
  }

  /**
   * @description Checks the filter array for a certain group type, and retuns whether if the value are all engated or not
   * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup_FrontendFiltersFacet[]} facets
   */
  static areAllNegated(facets, type) {
    return (
      facets
        ?.find((group) => group.value == type)
        ?.query?.filter((x) => x.operator == undefined || x.operator == null)
        ?.every((x) => x.negated) ?? false
    );
  }

  /**
   *
   * @param {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup_FrontendFiltersFacet[]} filters
   * @param {any} value
   * @param {number} groupIdx
   * @param {number} entryIdx
   * @returns {import("@root/types.api.local").MonitioAPI.FrontendFiltersGroup_FrontendFiltersFacet[]}
   */
  static toggleNegated(filters, value, groupIdx, entryIdx) {
    if (value == "customQuery") return filters;
    const selectedFilterGroup = filters[groupIdx];
    // remove the item 1st so we can work with it
    const items = selectedFilterGroup.query.splice(entryIdx, 2);
    items[0].negated = !items[0].negated;
    if (items[0].negated || !selectedFilterGroup.query.length) {
      selectedFilterGroup.query.unshift(items[0], { operator: "and" });
    } else {
      const rowOperator = this.checkQueryOperator(selectedFilterGroup.query);
      if (!selectedFilterGroup.query.slice(-1)[0].operator)
        selectedFilterGroup.query.push({ operator: rowOperator });
      selectedFilterGroup.query.push(items[0]);
    }
    return filters;
  }

  /**
   * @description Given a given [] (query) it computes what the correct operator is
   * @returns {"and"|"or"}
   */
  static checkQueryOperator(q) {
    let rowOperator = "or";
    // 1st check. If there's no operators still return imediatly a "or"
    if (!(q.filter((x) => x.operator)?.length > 0)) return rowOperator;
    if (
      // 2nd check. If every operator till now is == "and" we also add an "and"
      q.filter((x) => x.operator).every((x) => x.operator == "and")
    )
      rowOperator = "and";
    if (
      // 3rd check. If any value is negated we default to "or" again
      q.filter((x) => x.value).some((x) => x.negated)
    )
      rowOperator = "or";
    if (
      //4th if the last value is nageted we need to add a mandatory "add" after always
      q.filter((x) => x.value).slice(-1)?.[0].negated
    )
      rowOperator = "and";

    return rowOperator;
  }
}
