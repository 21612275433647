/* eslint-disable prefer-rest-params */
/* import type { dispatch } from "d3-dispatch";
import type { timer } from "d3-timer";
import type { drag as d3drag } from "d3-drag";
import { Layout, EventType, type Event } from "webcola";
import type { ID3StyleLayoutAdaptor } from "webcola";
import type { DragBehavior } from "d3"; */

import { Layout, EventType } from "webcola";

/* export interface D3Context {
  timer: typeof timer;
  drag: typeof d3drag;
  dispatch: typeof dispatch;
  event: any;
}
 */
/**
 * @internal
 */
class D3v7StyleLayoutAdaptor extends Layout {
  event;
  trigger(e) {
    const d3event = {
      type: EventType[e.type],
      alpha: e.alpha,
      stress: e.stress,
    };
    // the dispatcher is actually expecting something of type EventTarget as the second argument
    // so passing the thing above is totally abusing the pattern... not sure what to do about this yet
    this.event.call(d3event.type, d3event); // via d3 dispatcher, e.g. event.start(e);
  }

  // iterate layout using a d3.timer, which queues calls to tick repeatedly until tick returns true
  kick() {
    const t = this.#d3Context.timer(() => super.tick() && t.stop());
  }

  // a function to allow for dragging of nodes
  //drag: () => any;

  /**
   * @internal
   */
  #d3Context;

  constructor(d3Context) {
    super();
    this.#d3Context = d3Context;
    this.event = d3Context.dispatch(
      EventType[EventType.start],
      EventType[EventType.tick],
      EventType[EventType.end]
    );

    let drag;
    this.drag = function () {
      if (!drag) {
        drag = d3Context
          .drag()
          .subject(Layout.dragOrigin)
          .on("start.d3adaptor", (_evt, d) => {
            Layout.dragStart(d);
          })
          .on("drag.d3adaptor", (event, d) => {
            Layout.drag(d, event);
            this.resume(); // restart annealing
          })
          .on("end.d3adaptor", (_evt, d) => {
            Layout.dragEnd(d);
          });
      }

      if (!arguments.length) return drag;

      // this is the context of the function, i.e. the d3 selection
      //this//.on("mouseover.adaptor", colaMouseover)
      //.on("mouseout.adaptor", colaMouseout)
      arguments[0].call(drag);
    }.bind(this);
  }

  // a function for binding to events on the adapter
  on(eventType, listener) {
    if (typeof eventType === "string") {
      this.event.on(eventType, listener);
    } else {
      this.event.on(EventType[eventType], listener);
    }
    return this;
  }
}

export function d3v7adaptor(d3Context) {
  return new D3v7StyleLayoutAdaptor(d3Context);
}
