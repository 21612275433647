<template>
  <div
    class="m-trending-entities m-wrapper"
    :class="`m-wrapper--${viewsStore.nav}`"
  >
    <div class="m-subheader">
      <m-filter
        id="entity_relevance_page_filter"
        :scoped="false"
        :modelValue="viewFilters"
        :placeholder="t('components.search.placeholder_searchForAnything')"
      />
    </div>
    <div
      v-if="maxContextForTrendingEntities"
      class="m-max-context-for-trending-entities__container"
    >
      <p id="m_max_context_for_trending_entities" class="type--xsmall">
        {{
          t("views.trending-entities.maxContextForEntities", {
            days: maxContextForTrendingEntities.disclaimer,
          })
        }}
      </p>
      <m-icon
        id="m_max_context_for_trending_entities_icon"
        icon="info"
        variant="primary"
        status="active"
        size="xsmall"
        :tooltip="maxContextForTrendingEntities.tooltip"
        class="ml-1"
      />
    </div>
    <div
      ref="containerRef"
      id="scrollable"
      class="m-container"
      :class="{
        'm-container--scroll': isScrolled,
      }"
    >
      <div ref="containerWrapperRef" class="m-container__wrapper">
        <div v-if="!requestCompleted" class="m-ent-rel">
          <div class="m-ent-rel__timeline">
            <div class="m-ent-rel__mask"></div>
            <skeleton-loader
              type="box"
              width="88%"
              height="32px"
              corner="0"
              :border="false"
              class="m-ent-rel__dates"
            >
              <m-type-skeleton
                v-for="i in 2"
                :key="i"
                type="h6"
                size="default"
                width="70px"
                :lines="1"
                corner="0"
              />
            </skeleton-loader>
          </div>

          <skeleton-loader
            v-for="i in trendEntitiesSkeleton"
            :key="i"
            type="box"
            width="100%"
            height="32px"
            corner="0"
            :border="false"
            class="m-ent-rel m-ent-rel__row"
          >
            <m-type-skeleton
              type="h5"
              size="default"
              width="120px"
              :lines="1"
              corner="0"
              class="m-ent-rel__row m-ent-rel__key"
            />
            <skeleton-loader
              type="image"
              width="90%"
              height="32px"
              :border="false"
              corner="0"
              class="m-ent-rel__row m-ent-rel__values"
            />
          </skeleton-loader>
        </div>
        <m-trending-entities-results
          v-else-if="trendingEntities.length && granularCorporaFrequency"
          :trendingEntities="trendingEntities"
          :granularCorporaFrequency="granularCorporaFrequency"
          :parentScrollableElement="containerWrapperRef"
          :corporaCount="corporaCount"
          :selectionCount="selectionCount"
          @row-clicked="rowClicked"
          @item-clicked="itemClicked"
        />
        <div v-else class="m-ent-rel--empty">
          <h3 class="type--small type--nowrap type--empty">
            {{ t("views.trending-entities.noSearchResults") }}
          </h3>
          <h6 class="type--small type--nowrap">
            {{ t("views.trending-entities.tryChanging") }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, computed, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { useViewFilters } from "@hooks/useViewFilters";
import useWindowSize from "@hooks/useWindowSize";

import MHeaderFilters from "@components/navigation/MHeaderFilters.vue";
import MFilter from "@components/filter/MFilter.vue";
import MTrendingEntitiesResults from "@components/MTrendingEntitiesResults.vue";
import SkeletonLoader from "@priberam/skeleton-loader";
import MTypeSkeleton from "@components/skeletons/MTypeSkeleton.vue";
import MIcon from "@components/MIcon.vue";

import { DateTime } from "luxon";
import { useViewsStore } from "@root/store/modules/views";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useUserStore } from "@root/store/modules/user";
import { isEmpty } from "lodash-es";

import useDateFormat from "@root/hooks/useDateFormat";
import DateTimeUtils from "@root/utils/dateTime";

const { t } = useI18n();
const viewsStore = useViewsStore();
const { api } = useApi();
const { windowWidth } = useWindowSize();
const workspacesStore = useWorkspacesStore();
const userStore = useUserStore();
const { dateFormat } = useDateFormat();

const viewId = computed(() => route.params.viewId);

const route = useRoute();
const router = useRouter();
const {
  granularity,
  queryObject,
  decodeQueryObject,
  dateRestriction,
  refineBy,
} = useViewFilters(router, route);

const view = computed(() => viewsStore.getViewById(viewId.value));
const viewFilters = computed({
  get() {
    return view.value?.details?.filters;
  },

  set(val) {
    const updatedView = { ...view.value };
    updatedView.details.filters = val;
  },
});

/**
 * This element gets defined through a ref in the template,
 * this will be feeeded to its children to create intersection observers later
 * This is the (right) way!
 */
const containerRef = ref(null);
const containerWrapperRef = ref(null);

const rowClicked = () => undefined;
const itemClicked = () => undefined;

/** Data fetching */
const trendingEntities = ref(null);
const granularCorporaFrequency = ref(null);

const responseHasData = ref(true);
const requestCompleted = ref(false);

/**
 * The corporaCount and selectionCount are neeed all the way into the child
 * components, because all the calculations need these values for context.
 * So they get defined and passed into the child components
 */
const corporaCount = ref(null);
const selectionCount = ref(null);
const deleteTrendingEntities = () => {
  trendingEntities.value = granularCorporaFrequency.value = null;
};

/** Fetch the top trending entities and then obtain more information in each of them */
/**
 * @param {string} id Its a Guid
 * @param {Monitio.DTOs.BackendQueryObjectDTO} query
 */
const getTrendingEntities = async (
  id,
  granularity,
  dateRestriction,
  filters = [],
  refineBy,
  contextualFilters = null
) => {
  if (!isEmpty(contextualFilters)) filters.push({ facets: contextualFilters });

  let response;
  try {
    response = await api.search.trendingEntities(
      unref(id),
      granularity,
      dateRestriction,
      filters,
      refineBy
    );
  } catch (error) {
    console.debug(error);
  }

  if (
    response?.status != 200 ||
    response?.data?.dateCounts == null ||
    response?.data?.dateCounts == undefined
  ) {
    trendingEntities.value = [];
    responseHasData.value = false;
    requestCompleted.value = true;
    return;
  } else {
    //Map here to modify data incoming if needed. Check trending.vue line 307
    trendingEntities.value = response.data.properties;
  }

  if (!response.data.dateCounts) return;
  // Grab the dates and convert it to an array of objects (easier to work with)
  if (Object.keys(response.data.dateCounts)?.length === 0) {
    granularCorporaFrequency.value = [
      {
        date: DateTime.utc().toISO(),
        corporaFrequency: response.data.refinedResultsCount,
      },
    ];
  } else {
    granularCorporaFrequency.value = Object.keys(response.data.dateCounts).map(
      (key) => {
        return {
          date: key,
          corporaFrequency: response.data.dateCounts[key],
        };
      }
    );
  }
  corporaCount.value = response.data.corporaCount;
  selectionCount.value = response.data.refinedResultsCount;

  requestCompleted.value = true; // after api response flag is true
  responseHasData.value = true;
};

// Skeleton aux
// Skeleton Loader flag

const { windowHeight } = useWindowSize();

const trendEntitiesSkeleton = computed(() => {
  let max;
  if (windowHeight.value <= "1024") {
    max = 18;
  } else if (windowHeight.value <= "1280") {
    max = 20;
  } else if (windowHeight.value <= "1366") {
    max = 20;
  } else if (windowHeight.value <= "1440") {
    max = 24;
  } else if (windowHeight.value <= "1536") {
    max = 26;
  } else if (windowHeight.value <= "1920") {
    max = 28;
  }
  return max;
});

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);

const maxContextForTrendingEntities = computed(() => {
  if (
    router.currentRoute.value?.name == "trending-entities" &&
    !!workspaceConfig.value.personalization?.maxContextForTrendingEntities
  ) {
    let { date, duration } = DateTimeUtils.parseFromTimeSpan(
      workspaceConfig.value.personalization?.maxContextForTrendingEntities,
      DateTime.now(),
      { locale: userStore.i18nLanguage }
    );
    return {
      tooltip: {
        content: t("views.trending-entities.maxContextForEntitiesTooltip", {
          startDate: dateFormat(date, "static"),
          endDate: dateFormat(DateTime.now(), "static"),
        }),
        position: "bottom-center",
      },
      disclaimer: `${duration.as("days").toFixed(0)}`,
    };
  } else return null;
});

const isScrolled = ref(false);
const runScroll = () => {
  isScrolled.value =
    containerRef.value?.getBoundingClientRect()?.top !=
    containerRef.value?.childNodes[0]?.getBoundingClientRect()?.y;
};
onMounted(() => {
  containerRef.value.addEventListener("scroll", runScroll);
  getTrendingEntities(
    viewId.value,
    granularity.value,
    dateRestriction.value,
    queryObject.value.filters,
    refineBy.value,
    queryObject.value.contextualFilters
  );
});

onUnmounted(() => {
  deleteTrendingEntities();
});

onBeforeRouteUpdate((to, from) => {
  if (from.name != to.name) return true;
  /**
   * Handle the update of the props change
   * Ideally we wouls react to changes to the computed vars (viewId, queryObject) but that causes problems
   * betweens routes. So we need to use this hook, but can't use the computed vars because they only change AFTER
   * route change, and this hook runs BEFORE route change.
   **/

  // This is done so maybe a loading can take place? Else the user will view articles from another view for some milliseconds
  trendingEntities.value = null;
  granularCorporaFrequency.value = null;
  corporaCount.value = null;
  selectionCount.value = null;
  requestCompleted.value = false;

  const toQueryObject = decodeQueryObject(to.query.q);

  getTrendingEntities(
    to.params.viewId,
    toQueryObject.granularity || granularity.value,
    toQueryObject.dateRestriction || dateRestriction.value,
    toQueryObject.filters,
    toQueryObject.refineBy || refineBy.value,
    toQueryObject.contextualFilters
  );
});
</script>

<style lang="scss">
@import "@stylesheets/scss/components/trendingentities";
</style>

<style scoped lang="scss">
.m-trending-entities {
  .m-container {
    height: calc(100vh - 98px - 16px);

    &__wrapper {
      width: 100%;
      max-width: calc(100% - $spacing-4);
      padding: $spacing-5 $spacing-2 $spacing-5;
      margin: 0 auto;
    }
  }

  .m-ent-rel {
    &--empty {
      @include flex(center, center, column);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }

    &__timeline {
      @include flex(flex-start, center, row);

      .m-ent-rel {
        &__mask {
          width: 180px;
          margin-right: $spacing-2;
          @include ellipsis;
        }

        &__dates {
          @include flex(space-between, center, row);
          flex-grow: 1;

          .m-type-skeleton__h6 {
            width: unset;
          }

          h6 {
            text-align: center;
          }
        }
      }
    }

    &__row {
      height: 32px;
      margin: $spacing-2 $spacing-0;
      @include flex(flex-start, center, row);

      * {
        height: inherit;
      }

      .m-ent-rel {
        &__key {
          width: 180px;
          height: 24px;
          margin-right: $spacing-2;
          @include ellipsis;
        }

        &__values {
          @include flex(flex-start, center, row);
          flex-grow: 1;
          cursor: pointer;
        }
      }
    }
  }
}

.m-max-context-for-trending-entities {
  &__container {
    @include flex(flex-start, center, row);

    p {
      width: fit-content;
      align-self: end;
      color: $text-light;
    }
  }
}

@include mq(desktopXS) {
  .m-container {
    height: calc(100vh - 96px);
  }
}
</style>
